import jwtDecode from "jwt-decode";
import moment from "moment-timezone";
export function extractHostname(url) {
  var hostname;
  //find & remove protocol (http, ftp, etc.) and get hostname

  if (url.indexOf("//") > -1) {
    hostname = url.split("/")[2];
  } else {
    hostname = url.split("/")[0];
  }

  //find & remove port number
  hostname = hostname.split(":")[0];
  //find & remove "?"
  hostname = hostname.split("?")[0];
  return hostname;
}
export function getAverageRGB(imgEl) {
  var blockSize = 5,
    // only visit every 5 pixels
    defaultRGB = {
      r: 0,
      g: 0,
      b: 0
    },
    // for non-supporting envs
    canvas = document.createElement("canvas"),
    context = canvas.getContext && canvas.getContext("2d"),
    data,
    width,
    height,
    i = -4,
    length,
    rgb = {
      r: 0,
      g: 0,
      b: 0
    },
    count = 0;
  if (!context) {
    return defaultRGB;
  }
  height = canvas.height = imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height;
  width = canvas.width = imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width;
  context.drawImage(imgEl, 0, 0);
  try {
    data = context.getImageData(0, 0, width, height);
  } catch (e) {
    /* security error, img on diff domain */alert("x");
    return defaultRGB;
  }
  length = data.data.length;
  while ((i += blockSize * 4) < length) {
    ++count;
    rgb.r += data.data[i];
    rgb.g += data.data[i + 1];
    rgb.b += data.data[i + 2];
  }

  // ~~ used to floor values
  rgb.r = ~~(rgb.r / count);
  rgb.g = ~~(rgb.g / count);
  rgb.b = ~~(rgb.b / count);
  return rgb;
}

// export function randomColor() {
//   return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
// }
export function randomColorWithBrightness(brightness) {
  function randomChannel(brightness) {
    var r = 255 - brightness;
    var n = 0 | Math.random() * r + brightness;
    var s = n.toString(16);
    return s.length == 1 ? "0" + s : s;
  }
  return "#" + randomChannel(brightness) + randomChannel(brightness) + randomChannel(brightness);
}
export function hexToRgb(hex) {
  return hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, function (m, r, g, b) {
    return "#" + r + r + g + g + b + b;
  }).substring(1).match(/.{2}/g).map(function (x) {
    return parseInt(x, 16);
  });
}
export function randomColor() {
  return "#000000".replace(/0/g, (~~(Math.random() * 16)).toString(16));
}
export function getBWColor(hex) {
  if (hex.indexOf("#") === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error("Invalid HEX color ".concat(hex, "."));
  }
  var r = parseInt(hex.slice(0, 2), 16),
    g = parseInt(hex.slice(2, 4), 16),
    b = parseInt(hex.slice(4, 6), 16);
  // http://stackoverflow.com/a/3943023/112731
  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#FFFFFF";
}
export function convertToHoursAndMinutes(time) {
  var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "full";
  var unit = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "minutes";
  if (!time) {
    return "00 hrs 00 mins";
  }
  var minutesTime = unit === "minutes" ? time : Math.floor(time / 60);
  if (minutesTime < 1) {
    return "".concat(time, " ").concat(type === "short" ? "s" : "seconds");
  }
  var hours = minutesTime / 60;
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);
  if (rhours === 1) {
    rhours += type === "short" ? "h" : type === "medium" ? " hr" : " hour";
  } else if (rhours > 1) {
    rhours += type === "short" ? "h" : type === "medium" ? " hrs" : " hours";
  } else {
    rhours = "";
  }
  if (rminutes === 1) {
    rminutes += " minute";
  } else if (rminutes > 1) {
    rminutes += " minutes";
  } else {
    rminutes = "";
  }
  var workedTime = "".concat(rhours, " ").concat(rminutes);
  var trimmedTime = workedTime.trim();
  if (type === "short") {
    // if (trimmedTime.includes("h")) {
    trimmedTime = trimmedTime.replace(/\s+minutes/g, "m");
    trimmedTime = trimmedTime.replace(/\s+minute/g, "m");
    // }
  }
  if (type === "medium") {
    if (trimmedTime.includes("hr")) {
      trimmedTime = trimmedTime.replace(/\s+minutes/g, " mins");
      trimmedTime = trimmedTime.replace(/\s+minute/g, " min");
    }
  }
  return trimmedTime;
}
export function toHHMMSS(time) {
  var unit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "seconds";
  try {
    if (time === 0) {
      return "-- : -- : --";
    }
    var SECONDS = unit === "seconds" ? time : time * 60;
    return new Date(SECONDS * 1000).toISOString().substr(11, 8);
  } catch (err) {
    console.log(err);
    console.log(time);
    return "Not Available";
  }
}
export function toHHMM(time) {
  var unit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "seconds";
  var SECONDS = unit === "seconds" ? time : time * 60;
  return new Date(SECONDS * 1000).toISOString().substr(11, 5);
}
export function getBrowser() {
  // Get the user-agent string
  var userAgentString = navigator.userAgent;

  // Detect Chrome
  var chromeAgent = userAgentString.indexOf("Chrome") > -1;

  // Detect Internet Explorer
  var IExplorerAgent = userAgentString.indexOf("MSIE") > -1 || userAgentString.indexOf("rv:") > -1;

  // Detect Firefox
  var firefoxAgent = userAgentString.indexOf("Firefox") > -1;

  // Detect Safari
  var safariAgent = userAgentString.indexOf("Safari") > -1;

  // Discard Safari since it also matches Chrome
  if (chromeAgent && safariAgent) safariAgent = false;

  // Detect Opera
  var operaAgent = userAgentString.indexOf("OP") > -1;

  // Discard Chrome since it also matches Opera
  if (chromeAgent && operaAgent) chromeAgent = false;
  if (safariAgent) {
    return "safari";
  }
  if (chromeAgent) {
    return "chrome";
  }
  if (IExplorerAgent) {
    return "IE";
  }
  if (operaAgent) {
    return "opera";
  }
  if (firefoxAgent) {
    return "firefox";
  }
  return "unknown";
}
export function getOS() {
  var userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
    windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
    iosPlatforms = ["iPhone", "iPad", "iPod"],
    os = "";
  if (macosPlatforms.indexOf(platform) !== -1) {
    os = "Mac";
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = "iOS";
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = "Windows";
  } else if (/Android/.test(userAgent)) {
    os = "Android";
  } else if (!os && /Linux/.test(platform)) {
    os = "Linux";
  }
  return os;
}
export function getAuth() {
  try {
    var token = localStorage.getItem("token");
    if (!token) {
      throw new Error("not logged in");
    }
    var tokenDecoded = jwtDecode(token);
    if (!tokenDecoded) {
      throw new Error("invalid token");
    }
    if (moment.unix(tokenDecoded.exp).isBefore(new Date(), "second")) {
      throw new Error("expired");
    }
    return token;
  } catch (error) {
    console.log(error);
    localStorage.removeItem("token");
    // const { ipcRenderer } = window;
    // ipcRenderer.send("jwt_token_expired");
    return null;
  }
}
export function convertSecToHours(timeInSec) {
  if (timeInSec < 3600) {
    return timeInSec / 60 / 60;
  }
  //return Number(new Date(timeInSec * 1000).toISOString().substr(11, 2));
  return timeInSec / 3600;
}
export function convertSecToHoursAndMinutesWithTextShortForm(timeInSec) {
  var minutesTime = Math.floor(timeInSec / 60);
  var hours = minutesTime / 60;
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);
  if (rhours >= 1) {
    rhours = rhours.toString().length === 1 ? "0".concat(rhours) : rhours;
    rhours += "h";
  } else {
    rhours = "00h";
  }
  if (rminutes >= 1) {
    rminutes = rminutes.toString().length === 1 ? "0".concat(rminutes) : rminutes;
    rminutes += "m";
  } else {
    rminutes = "00m";
  }
  var time = "".concat(rhours, " ").concat(rminutes);
  return time.trim();
}
export function convertSecToHoursAndMinutesWithTextForm(timeInSec) {
  var minutesTime = Math.floor(timeInSec / 60);
  var hours = minutesTime / 60;
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);
  if (rhours >= 1) {
    rhours = rhours.toString().length === 1 ? "0".concat(rhours) : rhours;
    rhours += "h";
  } else {
    rhours = "00h";
  }
  if (rminutes >= 1) {
    rminutes = rminutes.toString().length === 1 ? "0".concat(rminutes) : rminutes;
    rminutes += "m";
  } else {
    rminutes = "00m";
  }
  var time = "".concat(rhours, " ").concat(rminutes);
  return time.trim();
}
export function convertSecToHoursAndMinutesAndSecWithTextShortForm(timeInSec) {
  if (Number(timeInSec) > 0 && Number(timeInSec) < 60) {
    return "00h 00m ".concat(timeInSec, "s");
  }
  var rseconds = Math.floor(Number(timeInSec) % 60);
  var minutesTime = Math.floor(timeInSec / 60);
  var hours = minutesTime / 60;
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);
  if (rhours >= 1) {
    rhours = rhours.toString().length === 1 ? "0".concat(rhours) : rhours;
    rhours += "h";
  } else {
    rhours = "00h";
  }
  if (rminutes >= 1) {
    rminutes = rminutes.toString().length === 1 ? "0".concat(rminutes) : rminutes;
    rminutes += "m";
  } else {
    rminutes = "00m";
  }
  if (rseconds >= 1) {
    rseconds = rseconds.toString().length === 1 ? "0".concat(rseconds) : rseconds;
    rseconds += "s";
  } else {
    rseconds = "00s";
  }
  var time = "".concat(rhours, " ").concat(rminutes, " ").concat(rseconds);
  return time.trim();
}
export function convertSecToHoursAndMinutesWithText(timeInSec) {
  var minutesTime = Math.floor(timeInSec / 60);
  var hours = minutesTime / 60;
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);
  if (rhours === 1) {
    rhours = rhours.toString().length === 1 ? "0".concat(rhours) : rhours;
    rhours += " hr";
  } else if (rhours > 1) {
    rhours = rhours.toString().length === 1 ? "0".concat(rhours) : rhours;
    rhours += " hrs";
  } else {
    rhours = "00 hr";
  }
  if (rminutes === 1) {
    rminutes = rminutes.toString().length === 1 ? "0".concat(rminutes) : rminutes;
    rminutes += " min";
  } else if (rminutes > 1) {
    rminutes = rminutes.toString().length === 1 ? "0".concat(rminutes) : rminutes;
    rminutes += " mins";
  } else {
    rminutes = "00 min";
  }
  var time = "".concat(rhours, " ").concat(rminutes);
  return time.trim();
}
export var colors = ["#d4bdf9", "#ccd3ff", "#a0ffb5", "#a39bf7", "#fccfb3", "#f4adb0", "#7dedb7", "#f4b486", "#aaa8f7", "#dcff96", "#f293ed", "#f993d0", "#9870ef", "#f2b1a4", "#fcb3cb", "#f2cd7d", "#ffe4b2", "#c6f489", "#ffb2dc", "#aefccc", "#ccffad", "#f990ac", "#de9ffc", "#89cbed", "#cd8df4", "#8defd7", "#b2ffda", "#9fc4fc", "#ffddc4", "#a0f7ba", "#f9939b", "#86f49e", "#ffeecc", "#9ecded", "#ca9ff2", "#9df99e", "#edbf76", "#b2ffb8", "#ffccf3", "#7a98e2", "#68e8e3", "#f9c475", "#f97c95", "#b2aaf7", "#ea85db", "#f78abf", "#4692e2", "#56d3a3", "#ddc549", "#dd7d4d"];
export var lightAndDarkColors = [{
  color: "#d4bdf9",
  fontColor: "#350b79"
}, {
  color: "#ccd3ff",
  fontColor: "#8a99ff"
}, {
  color: "#a0ffb5",
  fontColor: "#4dff73"
}, {
  color: "#a39bf7",
  fontColor: "#594cf0"
}, {
  color: "#fccfb3",
  fontColor: "#f88f4f"
}, {
  color: "#f4adb0",
  fontColor: "#e8545c"
}, {
  color: "#7dedb7",
  fontColor: "#159959"
}, {
  color: "#f4b486",
  fontColor: "#db6714"
}, {
  color: "#aaa8f7",
  fontColor: "#3c36ec"
}, {
  color: "#dcff96",
  fontColor: "#b6ff24"
}, {
  color: "#f293ed",
  fontColor: "#db1ad1"
}, {
  color: "#f993d0",
  fontColor: "#f339a9"
}, {
  color: "#9870ef",
  fontColor: "#5518d8"
}, {
  color: "#f2b1a4",
  fontColor: "#e04424"
}, {
  color: "#fcb3cb",
  fontColor: "#f73b7a"
}, {
  color: "#f2cd7d",
  fontColor: "#eaaf2e"
}, {
  color: "#ffe4b2",
  fontColor: "#ffb429"
}, {
  color: "#c6f489",
  fontColor: "#a0ed3b"
}, {
  color: "#ffb2dc",
  fontColor: "#ff52b1"
}, {
  color: "#aefccc",
  fontColor: "#35f880"
}, {
  color: "#ccffad",
  fontColor: "#97ff57"
}, {
  color: "#f990ac",
  fontColor: "#f65a84"
}, {
  color: "#de9ffc",
  fontColor: "#c85cfa"
}, {
  color: "#89cbed",
  fontColor: "#5bb8e6"
}, {
  color: "#cd8df4",
  fontColor: "#af4aed"
}, {
  color: "#8defd7",
  fontColor: "#1dcda1"
}, {
  color: "#b2ffda",
  fontColor: "#3dffa1"
}, {
  color: "#9fc4fc",
  fontColor: "#3483f9"
}, {
  color: "#ffddc4",
  fontColor: "#ffa666"
}, {
  color: "#a0f7ba",
  fontColor: "#43ef77"
}, {
  color: "#f9939b",
  fontColor: "#f5515f"
}, {
  color: "#86f49e",
  fontColor: "#1fea4b"
}, {
  color: "#ffeecc",
  fontColor: "#ffb829"
}, {
  color: "#9ecded",
  fontColor: "#3095d9"
}, {
  color: "#ca9ff2",
  fontColor: "#9947e6"
}, {
  color: "#9df99e",
  fontColor: "#49f34c"
}, {
  color: "#edbf76",
  fontColor: "#c7841a"
}, {
  color: "#b2ffb8",
  fontColor: "#4dff5b"
}, {
  color: "#ffccf3",
  fontColor: "#ff24cc"
}, {
  color: "#7a98e2",
  fontColor: "#2c58c9"
}, {
  color: "#68e8e3",
  fontColor: "#1aada8"
}, {
  color: "#f9c475",
  fontColor: "#eb910a"
}, {
  color: "#f97c95",
  fontColor: "#f41a46"
}, {
  color: "#b2aaf7",
  fontColor: "#2b16e9"
}, {
  color: "#ea85db",
  fontColor: "#c021a8"
}, {
  color: "#f78abf",
  fontColor: "#d70f6f"
}, {
  color: "#4692e2",
  fontColor: "#175292"
}, {
  color: "#56d3a3",
  fontColor: "#1f7a57"
}, {
  color: "#ddc549",
  fontColor: "#9b861c"
}, {
  color: "#dd7d4d",
  fontColor: "#a74d20"
}];
export var toCamel = function toCamel(s) {
  return s.replace(/([-_][a-z])/gi, function ($1) {
    return $1.toUpperCase().replace("-", "").replace("_", "");
  });
};
export function getOperatingSystem(window) {
  var operatingSystem = "not_known";
  if (window.navigator.appVersion.indexOf("Win") !== -1) {
    operatingSystem = "windows";
  }
  if (window.navigator.appVersion.indexOf("Mac") !== -1) {
    operatingSystem = "mac";
  }
  if (window.navigator.appVersion.indexOf("X11") !== -1) {
    operatingSystem = "unix";
  }
  if (window.navigator.appVersion.indexOf("Linux") !== -1) {
    operatingSystem = "linux";
  }
  return operatingSystem;
}
export function findIsWorkfolioSite() {
  var currentUrl = window.location.href;
  if (currentUrl.includes("workz.live")) {
    return "Workz";
  } else {
    return "workfolio";
  }
}
export function decodeHtmlEntities(htmlString) {
  var doc = new DOMParser().parseFromString(htmlString, "text/html");
  return doc.body.textContent;
}
export function encodeHtmlEntities(inputString) {
  return inputString.replace(/[&<>"']/g, function (_char) {
    switch (_char) {
      case "&":
        return "&amp;";
      case "<":
        return "&lt;";
      case ">":
        return "&gt;";
      case '"':
        return "&quot;";
      case "'":
        return "&#39;";
      default:
        return _char;
    }
  });
}