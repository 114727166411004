var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8;
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import { gql } from "@apollo/client";
export var ADD_HOLIDAY_MUTATION = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  mutation addHoliday(\n    $organisationId: String!\n    $holidayTitle: String!\n    $date: String!\n    $session: String!\n    $holidayType: String!\n    $timeZone: String!\n  ) {\n    addHoliday(\n      organisationId: $organisationId\n      holidayTitle: $holidayTitle\n      date: $date\n      session: $session\n      holidayType: $holidayType\n      timeZone: $timeZone\n    )\n  }\n"])));
export var HOLIDAY_LIST_QUERY = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  query holidayList(\n    $organisationId: String!\n    $holidayType: String\n    $startYear: String!\n    $endYear: String!\n    $limit: Int\n    $offset: Int\n  ) {\n    holidayList(\n      organisationId: $organisationId\n      holidayType: $holidayType\n      startYear: $startYear\n      endYear: $endYear\n      limit: $limit\n      offset: $offset\n    ) {\n      holidayData {\n        holidayId\n        title\n        session\n        date\n        type\n        dateFormat\n        day\n      }\n      holidayCount\n    }\n  }\n"])));
export var DELETE_HOLIDAY_MUTATION = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  mutation deleteHoliday($organisationId: String!, $holidayId: String!) {\n    deleteHoliday(organisationId: $organisationId, holidayId: $holidayId)\n  }\n"])));
export var EDIT_HOLIDAY_MUTATION = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  mutation editHoliday(\n    $organisationId: String!\n    $holidayTitle: String!\n    $date: String!\n    $session: String!\n    $holidayType: String!\n    $timeZone: String!\n    $holidayId: String!\n  ) {\n    editHoliday(\n      organisationId: $organisationId\n      holidayTitle: $holidayTitle\n      date: $date\n      session: $session\n      holidayType: $holidayType\n      timeZone: $timeZone\n      holidayId: $holidayId\n    )\n  }\n"])));
export var IMPORT_HOLIDAY_MUTATION = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  mutation importHoliday($organisationId: String!, $holidayDataArray: [HolidayDataArray]!, $timeZone: String!) {\n    importHoliday(organisationId: $organisationId, holidayDataArray: $holidayDataArray, timeZone: $timeZone)\n  }\n"])));
export var IMPORT_OLD_DATA_HOLIDAY_MUTATION = gql(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  mutation importOldHoliday($organisationId: String!, $timeZone: String!, $year: String!) {\n    importOldHoliday(organisationId: $organisationId, timeZone: $timeZone, year: $year)\n  }\n"])));
export var IMPORT_OLD_DATA_NO_HOLIDAY_MUTATION = gql(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  mutation importOldHolidayNo($organisationId: String!, $year: String!) {\n    importOldHolidayNo(organisationId: $organisationId, year: $year)\n  }\n"])));
export var LAST_YEAR_HOLIDAY_QUERY = gql(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  query importOldHolidayList($organisationId: String!, $year: String!, $timeZone: String!) {\n    importOldHolidayList(organisationId: $organisationId, year: $year, timeZone: $timeZone) {\n      year\n      lastYear\n    }\n  }\n"])));