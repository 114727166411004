var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15, _templateObject16, _templateObject17, _templateObject18;
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import { gql } from "@apollo/client";
export var ADD_LEAVE_TYPE_MUTATION = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  mutation addLeaveTypes(\n    $organisationId: String!\n    $userId: String!\n    $leaveType: String!\n    $leaveTypeDaysCount: Int\n    $resetLeaveBalance: String\n    $carryForward: String\n  ) {\n    addLeaveTypes(\n      organisationId: $organisationId\n      userId: $userId\n      leaveType: $leaveType\n      leaveTypeDaysCount: $leaveTypeDaysCount\n      resetLeaveBalance: $resetLeaveBalance\n      carryForward: $carryForward\n    )\n  }\n"])));
export var EDIT_LEAVE_TYPE_MUTATION = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  mutation editLeaveTypes(\n    $organisationId: String!\n    $userId: String!\n    $leaveType: String!\n    $leaveTypeId: String!\n    $leaveTypeDaysCount: Int\n    $resetLeaveBalance: String\n    $carryForward: String\n  ) {\n    editLeaveTypes(\n      organisationId: $organisationId\n      userId: $userId\n      leaveType: $leaveType\n      leaveTypeId: $leaveTypeId\n      leaveTypeDaysCount: $leaveTypeDaysCount\n      resetLeaveBalance: $resetLeaveBalance\n      carryForward: $carryForward\n    ) {\n      leaveTypeName\n      leaveTypeId\n      leaveTypeCount\n    }\n  }\n"])));
export var LEAVE_SETTINGS_LIST_QUERY = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  query leaveSettingsList($organisationId: String!, $limit: Int!, $offset: Int) {\n    leaveSettingsList(organisationId: $organisationId, limit: $limit, offset: $offset) {\n      leaveTypeName\n      leaveTypeId\n      leaveTypeCount\n      resetLeaveBalance\n      leaveCarryForward\n    }\n  }\n"])));
export var DELETE_LEAVE_TYPES_MUTATION = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  mutation deleteLeaveTypes($organisationId: String!, $leaveTypeId: String!) {\n    deleteLeaveTypes(organisationId: $organisationId, leaveTypeId: $leaveTypeId) {\n      leaveTypeId\n    }\n  }\n"])));
export var ADD_LEAVE_STATUS_CHANGE_USER_MUTATION = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  mutation addLeaveStatusChangeUser($organisationId: String!, $userList: [String]) {\n    addLeaveStatusChangeUser(organisationId: $organisationId, userList: $userList)\n  }\n"])));
export var LEAVE_SETTINGS_APPROVED_LIST_QUERY = gql(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  query leaveSettingsApprovedUserList($organisationId: String!) {\n    leaveSettingsApprovedUserList(organisationId: $organisationId) {\n      approvedList {\n        displayName\n        userId\n      }\n      leaveYearSettings\n      leavePermissions\n    }\n  }\n"])));
export var GET_LEAVE_USER_LISTING_QUERY = gql(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  query getLeaveUserListing($organisationId: String!, $teamId: String!, $userRole: String!) {\n    getLeaveUserListing(organisationId: $organisationId, teamId: $teamId, userRole: $userRole) {\n      userId\n      displayName\n    }\n  }\n"])));
export var UPDATE_LEAVE_TYPE_STATUS_MUTATION = gql(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  mutation updateLeaveTypeStatus($organisationId: String!, $leaveTypeStatus: String!, $leaveTypeId: String!) {\n    updateLeaveTypeStatus(\n      organisationId: $organisationId\n      leaveTypeStatus: $leaveTypeStatus\n      leaveTypeId: $leaveTypeId\n    ) {\n      leaveTypeId\n    }\n  }\n"])));
export var LEAVE_TYPE_ARCHIVED_LIST_QUERY = gql(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  query leaveTypeArchivedList($organisationId: String!, $limit: Int!, $offset: Int) {\n    leaveTypeArchivedList(organisationId: $organisationId, limit: $limit, offset: $offset) {\n      leaveTypeName\n      leaveTypeId\n      leaveTypeCount\n      resetLeaveBalance\n      leaveCarryForward\n    }\n  }\n"])));
export var UPDATE_LEAVE_SETTINGS_MUTATION = gql(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  mutation updateLeaveSettings($organisationId: String!, $yearType: String!) {\n    updateLeaveSettings(organisationId: $organisationId, yearType: $yearType)\n  }\n"])));
export var UPDATE_LEAVE_PERMISSION_SETTINGS_MUTATION = gql(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  mutation updateLeavePermissionSettings($organisationId: String!, $leaveApplyForPastDay: String!) {\n    updateLeavePermissionSettings(organisationId: $organisationId, leaveApplyForPastDay: $leaveApplyForPastDay)\n  }\n"])));
export var LEAVE_TYPE_LIST_COUNT_QUERY = gql(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n  query leaveTypeListCount($organisationId: String!) {\n    leaveTypeListCount(organisationId: $organisationId) {\n      leaveCount\n      archivedCount\n    }\n  }\n"])));
export var ADD_PERMISSION_DETAILS_MUTATION = gql(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n  mutation addPermissionDetails(\n    $organisationId: String!\n    $userId: String!\n    $permissionName: String!\n    $applicableWithin: String!\n    $totalAllowedTime: Int!\n    \n  ) {\n    addPermissionDetails(\n      organisationId: $organisationId\n      userId: $userId\n      permissionName: $permissionName\n      applicableWithin: $applicableWithin\n      totalAllowedTime: $totalAllowedTime\n      \n    )\n  }\n"])));
export var EDIT_PERMISSION_DETAILS_MUTATION = gql(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["\n  mutation editPermissionDetails(\n    $organisationId: String!\n    $userId: String!\n    $permissionName: String!\n    $applicableWithin: String!\n    $totalAllowedTime: Int!\n    $permissionId: String!\n  ) {\n    editPermissionDetails(\n      organisationId: $organisationId\n      userId: $userId\n      permissionName: $permissionName\n      applicableWithin: $applicableWithin\n      totalAllowedTime: $totalAllowedTime\n      permissionId: $permissionId\n    ) {\n      permissionName\n      permissionId\n      applicableWithin\n      totalAllowedTimeInSec\n      totalAllowedTime\n      totalAllowedTimeInMin\n      minTimePerRequestInSec\n      minTimePerRequest\n      minTimePerRequestInMin\n      maxTimePerRequestInSec\n      maxTimePerRequest\n      maxTimePerRequestInMin\n      totalAllowedRequest\n    }\n  }\n"])));
export var DELETE_PERMISSION_DETAILS_MUTATION = gql(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral(["\n  mutation deletePermissionDetails($organisationId: String!, $permissionId: String!) {\n    deletePermissionDetails(organisationId: $organisationId, permissionId: $permissionId) {\n      permissionId\n    }\n  }\n"])));
export var UPDATE_PERMISSION_STATUS_MUTATION = gql(_templateObject16 || (_templateObject16 = _taggedTemplateLiteral(["\n  mutation updatePermissionStatus($organisationId: String!, $permissionStatus: String!, $permissionId: String!) {\n    updatePermissionStatus(\n      organisationId: $organisationId\n      permissionStatus: $permissionStatus\n      permissionId: $permissionId\n    ) {\n      permissionId\n    }\n  }\n"])));
export var PERMISSION_SETTINGS_LIST_QUERY = gql(_templateObject17 || (_templateObject17 = _taggedTemplateLiteral(["\n  query permissionList($organisationId: String!, $limit: Int!, $offset: Int) {\n    permissionList(organisationId: $organisationId, limit: $limit, offset: $offset) {\n      permissionName\n      permissionId\n      applicableWithin\n      totalAllowedTimeInSec\n      totalAllowedTime\n      totalAllowedTimeInMin\n      minTimePerRequestInSec\n      minTimePerRequest\n      minTimePerRequestInMin\n      maxTimePerRequestInSec\n      maxTimePerRequest\n      maxTimePerRequestInMin\n      totalAllowedRequest\n    }\n  }\n"])));
export var PERMISSION_ARCHIVED_SETTINGS_LIST_QUERY = gql(_templateObject18 || (_templateObject18 = _taggedTemplateLiteral(["\n  query permissionArchivedList($organisationId: String!, $limit: Int!, $offset: Int) {\n    permissionArchivedList(organisationId: $organisationId, limit: $limit, offset: $offset) {\n      permissionName\n      permissionId\n      applicableWithin\n      totalAllowedTimeInSec\n      totalAllowedTime\n      totalAllowedTimeInMin\n      minTimePerRequestInSec\n      minTimePerRequest\n      minTimePerRequestInMin\n      maxTimePerRequestInSec\n      maxTimePerRequest\n      maxTimePerRequestInMin\n      totalAllowedRequest\n    }\n  }\n"])));