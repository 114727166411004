import React from "react";
import "./App.css";
import {
  Dashboard,
  MyProjects,
  ScreenShots,
  MyTeams,
  ProjectDetails,
  TimeSheet,
  TaskDetails,
  ActivityTracking,
  PersonalStatistics,
  ReviewUsage,
  AppsSummary,
  Timelapse,
  ScheduledReports,
  ApiTokens,
  Teams,
  Employees,
  TimesheetSettings,
  Billing,
  PersonalDashboard,
  PersonalReviewUsage,
  PersonalSettings,
  UserContext,
  PrivateRoute,
  PublicRoute,
  AppsAndWebsitesHistory,
  ChangePassword,
  ForgotPassword,
  CreatePassword,
  OnBoarding,
  WebPageSignUp,
  MemberOverviewPage,
  RiskUserHistory,
  RiskRuleSetting,
  WarningScreen,
  UserStatus,
  ThankYou,
  EmployeeTimeline,
  ChargebeeSubscription,
  ManualTimeEntries,
  ManualTimeEntriesReview,
  OrganisationSettings,
  ManualPages,
  Login,
  BillingPlan,
  CustomReport,
  MonthlyAttendance,
  DailyAttendance,
  AppsUsage,
  WorkActivityLog,
  MonthlyInOut,
  PerformanceReport,
  ProjectReport,
  WorkLoadAnalysisReport,
  ManualTimeReport,
  Integrations,
  ShiftTime,
  projectSettings,
  ManualTime,
  AddTask,
  TaskOverview,
  ViewLeave,
  LeaveHistory,
  HolidayImport,
  AdminViewLeave,
  LeaveSettings,
  RealTimeNotification,
  EditTimesheet,
  ManagePermission,
  LateClockInReport,
  OverTimeReport,
  LiveStatsPage,
  UserDetails,
  WorkfolioAffiliates,
  LicenseCount,
  WorkfolioMaintenance,
} from "@workfolio/shared";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import jwtDecode from "jwt-decode";
import { ToastContainer } from "@workfolio/shared/node_modules/react-toastify";
import "@workfolio/shared/node_modules/react-toastify/dist/ReactToastify.min.css";
import LogRocket from "logrocket";

function App() {
  const [user, setUser] = React.useState(
    localStorage.getItem("token")
      ? jwtDecode(localStorage.getItem("token"))
      : null
  );

  // React.useEffect(() => {
  //   console.log("enter");

  //   let chatId = "6023ec849c4f165d47c1ec80/1eu657qei";

  //   if (
  //     user &&
  //     user.organisations &&
  //     user.organisations[0] &&
  //     user.organisations[0].planType == "pro"
  //   ) {
  //     chatId = "6023ec849c4f165d47c1ec80/1gpf4msur";
  //   } else {
  //     chatId = "6023ec849c4f165d47c1ec80/1eu657qei";
  //   }

  //   console.log({ chatId });

  //   var Tawk_API = Tawk_API || {},
  //     Tawk_LoadStart = new Date();
  //   (function () {
  //     console.log("function enter");
  //     var s1 = document.createElement("script"),
  //       s0 = document.getElementsByTagName("script")[0];
  //     s1.async = true;
  //     s1.src = "https://embed.tawk.to/" + chatId;
  //     s1.charset = "UTF-8";
  //     s1.setAttribute("crossorigin", "*");
  //     s0.parentNode.insertBefore(s1, s0);
  //   })();
  // }, []);

  React.useEffect(() => {
    if (
      user &&
      user?.userId &&
      user?.organisations[0].id == "472aae70-8136-11ed-8514-c1cfa76c35bb"
    ) {
      LogRocket.init("bmop9o/workfolio");
    }
  }, [user]);

  const enhancedRouteProps = {
    route: Route,
    link: Link,
    redirect: Redirect,
    user,
  };

  let siteName = "workfolio";

  if (window.location.href.includes("workz.live")) {
    siteName = "Workz";
  } else {
    siteName = "workfolio";
  }

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <ToastContainer />
      <Router>
        <Switch>
          {/* workfolio menu url start*/}
          <Route exact path="/">
            <Redirect to="/overview" />
          </Route>
          <PublicRoute
            path="/login"
            component={Login}
            {...enhancedRouteProps}
          />
          {/* <PublicRoute
            path="/login"
            component={DesktopLogin}
            {...enhancedRouteProps}
          /> */}
          <PublicRoute
            exact
            path="/password/:passwordToken"
            component={ChangePassword}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PublicRoute
            exact
            path="/create-password/:passwordToken"
            component={CreatePassword}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PublicRoute
            exact
            path="/forgot-password"
            component={ForgotPassword}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          {/* <PrivateRoute
            exact
            path="/wf-user-status"
            component={UserStatus}
            route={Route}
            link={Link}
            redirect={Redirect}
          /> */}
          <PublicRoute
            exact
            path={["/signup/:link?", "/signup"]}
            component={WebPageSignUp}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PublicRoute
            exact
            path="/workfolio-affiliates"
            component={WorkfolioAffiliates}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PublicRoute
            exact
            path="/workfolio-maintenance"
            component={WorkfolioMaintenance}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          
          {/* <PrivateRoute
            exact
            path="/on-boarding"
            component={OnBoarding}
            componentProps={{ linkComponent: Link, linkProps: "to" }}
            route={Route}
            link={Link}
            redirect={Redirect}
          /> */}
          <PrivateRoute
            exact
            path={["/on-boarding/:link?", "/on-boarding"]}
            component={OnBoarding}
            componentProps={{ linkComponent: Link, linkProps: "to" }}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path="/member-overview"
            component={MemberOverviewPage}
            componentProps={{ linkComponent: Link, linkProps: "to" }}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path="/risk-user-history"
            component={RiskUserHistory}
            componentProps={{ linkComponent: Link, linkProps: "to" }}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path="/settings/risk-settings"
            component={RiskRuleSetting}
            componentProps={{ linkComponent: Link, linkProps: "to" }}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path="/overview"
            component={Dashboard}
            componentProps={{ linkComponent: Link, linkProps: "to" }}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PublicRoute
            exact
            path="/error"
            component={WarningScreen}
            componentProps={{ linkComponent: Link, linkProps: "to" }}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path="/app-error"
            component={WarningScreen}
            componentProps={{ linkComponent: Link, linkProps: "to" }}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path="/my-teams"
            component={MyTeams}
            componentProps={{ linkComponent: Link, linkProps: "to" }}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path="/my-projects"
            component={MyProjects}
            componentProps={{ linkComponent: Link, linkProps: "to" }}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path={["/screenshots/:url", "/screenshots"]}
            // render={(routeProps) => (
            //   <SideMenu {...routeProps} linkComponent={Link} linkAttribute="to">
            //     <ScreenShots
            //       {...routeProps}
            //       linkComponent={Link}
            //       linkProps="to"
            //     />
            //   </SideMenu>
            // )}
            component={ScreenShots}
            componentProps={{ linkComponent: Link, linkProps: "to" }}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path={["/timelapse/:url", "/timelapse"]}
            component={Timelapse}
            componentProps={{ linkComponent: Link, linkProps: "to" }}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path="/manual-time-entries"
            component={ManualTimeEntries}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          {/* <PrivateRoute
            exact
            path="/manual-time/review-manual-time"
            component={ManualTimeEntriesReview}
            componentProps={{ linkComponent: Link, linkProps: "to" }}
            route={Route}
            link={Link}
            redirect={Redirect}
          /> */}
          <PrivateRoute
            exact
            path="/work-history"
            component={ManualPages}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path="/timesheet"
            component={TimeSheet}
            componentProps={{ linkComponent: Link, linkProps: "to" }}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path="/review-usage"
            component={ReviewUsage}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path="/apps-summary"
            component={AppsSummary}
            componentProps={{ linkComponent: Link, linkProps: "to" }}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path="/settings/email-reports"
            component={ScheduledReports}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <Route exact path="/settings">
            <Redirect to="/settings/manage-teams" />
          </Route>
          <PrivateRoute
            exact
            path="/settings/manage-teams"
            // render={(routeProps) => (
            //   <SideMenu {...routeProps} linkComponent={Link} linkAttribute="to">
            //     <Teams />
            //   </SideMenu>
            // )}
            component={Teams}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path="/settings/manage-employees"
            component={Employees}
            componentProps={{ linkComponent: Link, linkProps: "to" }}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          {/* <Route
            exact
            path="/settings/access-control"
            render={(routeProps) => (
              <SideMenu {...routeProps} linkComponent={Link} linkAttribute="to">
                <AccessControl />
              </SideMenu>
            )}
          /> */}
          <PrivateRoute
            exact
            path="/settings/track-settings"
            component={TimesheetSettings}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path="/settings/rebrand-settings"
            component={OrganisationSettings}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          {/* <PrivateRoute
            exact
            path="/settings/api-token"
            component={ApiTokens}
            route={Route}
            link={Link}
            redirect={Redirect}
          /> */}
          <PrivateRoute
            exact
            path="/settings/project-settings"
            component={projectSettings}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path="/billing"
            component={siteName === "workfolio" ? Billing : BillingPlan}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path="/project-details/:projectId"
            component={ProjectDetails}
            componentProps={{ linkComponent: Link, linkProps: "to" }}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path="/personal-statistics/:url"
            // render={(routeProps) => (
            //   <SideMenu {...routeProps} linkComponent={Link} linkAttribute="to">
            //     <PersonalStatistics {...routeProps} />
            //   </SideMenu>
            // )}
            component={PersonalStatistics}
            componentProps={{ linkComponent: Link, linkProps: "to" }}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          {/* workfolio menu url end*/}
          {/* Not integrated in menu start */}
          <PrivateRoute
            exact
            path="/task-details/:projectId/:taskId"
            // render={(routeProps) => (
            //   <SideMenu {...routeProps} linkComponent={Link} linkAttribute="to">
            //     <TaskDetails
            //       {...routeProps}
            //       linkComponent={Link}
            //       linkProps="to"
            //     />
            //   </SideMenu>
            // )}
            component={TaskDetails}
            componentProps={{ linkComponent: Link, linkProps: "to" }}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path="/activity-tracking"
            // render={(routeProps) => (
            //   <SideMenu {...routeProps} linkComponent={Link} linkAttribute="to">
            //     <ActivityTracking />
            //   </SideMenu>
            // )}
            component={ActivityTracking}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          {/* Not integrated in menu end */}
          {/* Personal app start*/}
          <PrivateRoute
            exact
            path="/personal/dashboard"
            // render={(routeProps) => (
            //   <SideMenu {...routeProps} linkComponent={Link} linkAttribute="to">
            //     <PersonalDashboard />
            //   </SideMenu>
            // )}
            component={PersonalDashboard}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path="/personal/review-usage"
            // render={(routeProps) => (
            //   <SideMenu {...routeProps} linkComponent={Link} linkAttribute="to">
            //     <PersonalReviewUsage linkComponent={Link} />
            //   </SideMenu>
            // )}
            component={PersonalReviewUsage}
            componentProps={{ linkComponent: Link }}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path="/account-settings"
            // render={(routeProps) => (
            //   <SideMenu {...routeProps} linkComponent={Link} linkAttribute="to">
            //     <PersonalSettings />
            //   </SideMenu>
            // )}
            component={PersonalSettings}
            componentProps={{ linkComponent: Link, linkProps: "to" }}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path={["/apps-history/:url", "/apps-history"]}
            component={AppsAndWebsitesHistory}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path="/thankyou"
            component={ThankYou}
            componentProps={{ linkComponent: Link, linkProps: "to" }}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path="/timeline"
            component={EmployeeTimeline}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path="/subscription-renewal"
            component={ChargebeeSubscription}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path="/reports/custom-report"
            component={CustomReport}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path="/reports/monthly-attendance"
            component={MonthlyAttendance}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path={[
              "/reports/daily-attendance/:pageType",
              "/reports/daily-attendance",
            ]}
            component={DailyAttendance}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path="/reports/shift-time"
            component={ShiftTime}
            componentProps={{ linkComponent: Link, linkProps: "to" }}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path="/reports/apps-usage"
            component={AppsUsage}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path="/reports/work-activity-log"
            component={WorkActivityLog}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path="/reports/monthly-in-out"
            component={MonthlyInOut}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path="/performance-report"
            component={PerformanceReport}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path="/project-report"
            component={ProjectReport}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path="/reports/work-load-analysis"
            component={WorkLoadAnalysisReport}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path="/reports/manual-time-report"
            component={ManualTimeReport}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path="/integrations"
            component={Integrations}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          {/* <PrivateRoute
            exact
            path="/manual-time/add-manual-time"
            component={ManualTime}
            route={Route}
            link={Link}
            redirect={Redirect}
          /> */}
          <PrivateRoute
            exact
            path="/manual-time"
            component={ManualTime}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path="/my-work"
            component={PersonalStatistics}
            componentProps={{ linkComponent: Link, linkProps: "to" }}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path="/my-tasks"
            component={AddTask}
            componentProps={{ linkComponent: Link, linkProps: "to" }}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path="/task-overview"
            component={TaskOverview}
            componentProps={{ linkComponent: Link, linkProps: "to" }}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path="/apply-leave"
            component={ViewLeave}
            componentProps={{ linkComponent: Link, linkProps: "to" }}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path="/manage-leave"
            component={AdminViewLeave}
            componentProps={{ linkComponent: Link, linkProps: "to" }}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path="/leave-summary"
            component={LeaveHistory}
            componentProps={{ linkComponent: Link, linkProps: "to" }}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path="/manage-holiday"
            component={HolidayImport}
            componentProps={{ linkComponent: Link, linkProps: "to" }}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path="/view-holiday"
            component={HolidayImport}
            componentProps={{ linkComponent: Link, linkProps: "to" }}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path="/settings/leave-settings"
            component={LeaveSettings}
            componentProps={{ linkComponent: Link, linkProps: "to" }}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path="/real-time-alert"
            component={RealTimeNotification}
            componentProps={{ linkComponent: Link, linkProps: "to" }}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path="/edit-timesheet"
            component={EditTimesheet}
            componentProps={{ linkComponent: Link, linkProps: "to" }}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path="/manage-permission"
            component={ManagePermission}
            componentProps={{ linkComponent: Link, linkProps: "to" }}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path="/reports/late-clock-in"
            component={LateClockInReport}
            componentProps={{ linkComponent: Link, linkProps: "to" }}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path="/reports/over-time-report"
            component={OverTimeReport}
            componentProps={{ linkComponent: Link, linkProps: "to" }}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path="/live-stats-page"
            component={LiveStatsPage}
            componentProps={{ linkComponent: Link, linkProps: "to" }}
            route={Route}
            link={Link}
            redirect={Redirect}
          />{" "}
          <PrivateRoute
            exact
            path="/user-details"
            component={UserDetails}
            componentProps={{ linkComponent: Link, linkProps: "to" }}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          <PrivateRoute
            exact
            path="/license-count"
            component={LicenseCount}
            componentProps={{ linkComponent: Link, linkProps: "to" }}
            route={Route}
            link={Link}
            redirect={Redirect}
          />
          {/* Personal app end*/}
          <Route path="/" render={(routeProps) => <div>Home</div>} />
        </Switch>
      </Router>
    </UserContext.Provider>
  );
}

export default App;
