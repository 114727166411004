var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15;
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import { gql } from "@apollo/client";
export var GET_DAILY_ATTENDANCE_QUERY = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  query getDailyAttendance(\n    $organisationId: String!\n    $userId: String\n    $startDate: Float!\n    $endDate: Float!\n    $timeZone: String!\n    $limit: Int\n    $offset: Int\n    $teamId: String!\n    $userRole: String!\n  ) {\n    getDailyAttendance(\n      organisationId: $organisationId\n      userId: $userId\n      startDate: $startDate\n      endDate: $endDate\n      timeZone: $timeZone\n      limit: $limit\n      offset: $offset\n      teamId: $teamId\n      userRole: $userRole\n    ) {\n      userId\n      displayName\n      profileUrl\n      totalWorkSec\n      totalWorkHours\n      clockIn\n      clockOut\n      workTime\n    }\n  }\n"])));
export var USER_LISTING_QUERY = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  query getUserListing($organisationId: String!, $teamId: String!, $userRole: String!) {\n    getUserListing(organisationId: $organisationId, teamId: $teamId, userRole: $userRole) {\n      userId\n      displayName\n    }\n  }\n"])));
export var GET_DAILY_ATTENDANCE_EXPORT_QUERY = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  query getDailyAttendanceExport(\n    $organisationId: String!\n    $userId: String\n    $startDate: Float!\n    $endDate: Float!\n    $timeZone: String!\n    $teamId: String!\n    $userRole: String!\n  ) {\n    getDailyAttendanceExport(\n      organisationId: $organisationId\n      userId: $userId\n      startDate: $startDate\n      endDate: $endDate\n      timeZone: $timeZone\n      teamId: $teamId\n      userRole: $userRole\n    ) {\n      userId\n      displayName\n      profileUrl\n      totalWorkSec\n      totalWorkHours\n      clockIn\n      clockOut\n      workTime\n    }\n  }\n"])));
export var GET_DAILY_WORKED_QUERY = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  query getDailyWorkedDetails(\n    $organisationId: String!\n    $userId: String\n    $startDate: Float!\n    $endDate: Float!\n    $timeZone: String!\n    $limit: Int\n    $offset: Int\n    $teamId: String!\n    $userRole: String!\n  ) {\n    getDailyWorkedDetails(\n      organisationId: $organisationId\n      userId: $userId\n      startDate: $startDate\n      endDate: $endDate\n      timeZone: $timeZone\n      limit: $limit\n      offset: $offset\n      teamId: $teamId\n      userRole: $userRole\n    ) {\n      userId\n      displayName\n      profileUrl\n      totalWorkSec\n      totalWorkHours\n      clockIn\n      clockOut\n      workTime\n      employeeId\n      uniqueUrl\n    }\n  }\n"])));
export var GET_DAILY_ABSENT_QUERY = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  query getDailyAbsentDetails(\n    $organisationId: String!\n    $userId: String\n    $startDate: Float!\n    $endDate: Float!\n    $timeZone: String!\n    $limit: Int\n    $offset: Int\n    $teamId: String!\n    $userRole: String!\n  ) {\n    getDailyAbsentDetails(\n      organisationId: $organisationId\n      userId: $userId\n      startDate: $startDate\n      endDate: $endDate\n      timeZone: $timeZone\n      limit: $limit\n      offset: $offset\n      teamId: $teamId\n      userRole: $userRole\n    ) {\n      userId\n      displayName\n      profileUrl\n      totalWorkSec\n      totalWorkHours\n      clockIn\n      clockOut\n      workTime\n      leaveStatus\n      employeeId\n      uniqueUrl\n    }\n  }\n"])));
export var GET_DAILY_LATE_QUERY = gql(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  query getLateClockInDetails(\n    $organisationId: String!\n    $userId: String\n    $startDate: Float!\n    $endDate: Float!\n    $timeZone: String!\n    $limit: Int\n    $offset: Int\n    $teamId: String!\n    $userRole: String!\n  ) {\n    getLateClockInDetails(\n      organisationId: $organisationId\n      userId: $userId\n      startDate: $startDate\n      endDate: $endDate\n      timeZone: $timeZone\n      limit: $limit\n      offset: $offset\n      teamId: $teamId\n      userRole: $userRole\n    ) {\n      userId\n      displayName\n      profileUrl\n      totalWorkSec\n      totalWorkHours\n      clockIn\n      clockOut\n      workTime\n      lateTime\n      expectedClockIn\n      employeeId\n    }\n  }\n"])));
export var USER_WORKED_LISTING_QUERY = gql(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  query getUserListingForWorked(\n    $organisationId: String!\n    $teamId: String!\n    $userRole: String!\n    $startDate: Float!\n    $endDate: Float!\n    $timeZone: String!\n  ) {\n    getUserListingForWorked(\n      organisationId: $organisationId\n      teamId: $teamId\n      userRole: $userRole\n      startDate: $startDate\n      endDate: $endDate\n      timeZone: $timeZone\n    ) {\n      value\n      label\n    }\n  }\n"])));
export var USER_ABSENT_LISTING_QUERY = gql(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  query getUserListingForAbsent(\n    $organisationId: String!\n    $teamId: String!\n    $userRole: String!\n    $startDate: Float!\n    $endDate: Float!\n    $timeZone: String!\n  ) {\n    getUserListingForAbsent(\n      organisationId: $organisationId\n      teamId: $teamId\n      userRole: $userRole\n      startDate: $startDate\n      endDate: $endDate\n      timeZone: $timeZone\n    ) {\n      value\n      label\n    }\n  }\n"])));
export var USER_LATE_LISTING_QUERY = gql(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  query getUserListingForLate(\n    $organisationId: String!\n    $teamId: String!\n    $userRole: String!\n    $startDate: Float!\n    $endDate: Float!\n    $timeZone: String!\n  ) {\n    getUserListingForLate(\n      organisationId: $organisationId\n      teamId: $teamId\n      userRole: $userRole\n      startDate: $startDate\n      endDate: $endDate\n      timeZone: $timeZone\n    ) {\n      value\n      label\n    }\n  }\n"])));
export var USER_UNINFORMED_LEAVE_QUERY = gql(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  query getUserUninformedLeave(\n    $organisationId: String!\n    $teamId: String!\n    $userRole: String!\n    $startDate: Float!\n    $endDate: Float!\n    $timeZone: String!\n  ) {\n    getUserUninformedLeave(\n      organisationId: $organisationId\n      teamId: $teamId\n      userRole: $userRole\n      startDate: $startDate\n      endDate: $endDate\n      timeZone: $timeZone\n    ) {\n      value\n      label\n    }\n  }\n"])));
export var GET_DAILY_WORKED_QUERY_EXPORT = gql(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  query getDailyWorkedDetailsExport(\n    $organisationId: String!\n    $userId: String\n    $startDate: Float!\n    $endDate: Float!\n    $timeZone: String!\n    $teamId: String!\n    $userRole: String!\n  ) {\n    getDailyWorkedDetailsExport(\n      organisationId: $organisationId\n      userId: $userId\n      startDate: $startDate\n      endDate: $endDate\n      timeZone: $timeZone\n      teamId: $teamId\n      userRole: $userRole\n    ) {\n      userId\n      displayName\n      profileUrl\n      totalWorkSec\n      totalWorkHours\n      clockIn\n      clockOut\n      workTime\n      employeeId\n    }\n  }\n"])));
export var GET_DAILY_ABSENT_QUERY_EXPORT = gql(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n  query getDailyAbsentDetailsExport(\n    $organisationId: String!\n    $userId: String\n    $startDate: Float!\n    $endDate: Float!\n    $timeZone: String!\n    $teamId: String!\n    $userRole: String!\n  ) {\n    getDailyAbsentDetailsExport(\n      organisationId: $organisationId\n      userId: $userId\n      startDate: $startDate\n      endDate: $endDate\n      timeZone: $timeZone\n      teamId: $teamId\n      userRole: $userRole\n    ) {\n      userId\n      displayName\n      profileUrl\n      totalWorkSec\n      totalWorkHours\n      clockIn\n      clockOut\n      workTime\n      leaveStatus\n      employeeId\n    }\n  }\n"])));
export var GET_DAILY_LATE_QUERY_EXPORT = gql(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n  query getLateClockInDetailsExport(\n    $organisationId: String!\n    $userId: String\n    $startDate: Float!\n    $endDate: Float!\n    $timeZone: String!\n    $teamId: String!\n    $userRole: String!\n  ) {\n    getLateClockInDetailsExport(\n      organisationId: $organisationId\n      userId: $userId\n      startDate: $startDate\n      endDate: $endDate\n      timeZone: $timeZone\n      teamId: $teamId\n      userRole: $userRole\n    ) {\n      userId\n      displayName\n      profileUrl\n      totalWorkSec\n      totalWorkHours\n      clockIn\n      clockOut\n      workTime\n      lateTime\n      expectedClockIn\n      employeeId\n    }\n  }\n"])));
export var GET_UNINFORMED_LEAVE_DETAILS_QUERY = gql(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["\n  query getUninformedLeaveDetails(\n    $organisationId: String!\n    $userId: String\n    $startDate: Float!\n    $endDate: Float!\n    $timeZone: String!\n    $limit: Int\n    $offset: Int\n    $teamId: String!\n    $userRole: String!\n  ) {\n    getUninformedLeaveDetails(\n      organisationId: $organisationId\n      userId: $userId\n      startDate: $startDate\n      endDate: $endDate\n      timeZone: $timeZone\n      limit: $limit\n      offset: $offset\n      teamId: $teamId\n      userRole: $userRole\n    ) {\n      userId\n      displayName\n      emailId\n      profileUrl\n      uniqueUrl\n      employeeId\n    }\n  }\n"])));
export var GET_UNINFORMED_LEAVE_DETAILS_EXPORT_QUERY = gql(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral(["\n  query getUninformedLeaveDetailsExport(\n    $organisationId: String!\n    $userId: String\n    $startDate: Float!\n    $endDate: Float!\n    $timeZone: String!\n    $teamId: String!\n    $userRole: String!\n  ) {\n    getUninformedLeaveDetailsExport(\n      organisationId: $organisationId\n      userId: $userId\n      startDate: $startDate\n      endDate: $endDate\n      timeZone: $timeZone\n      teamId: $teamId\n      userRole: $userRole\n    ) {\n      userId\n      displayName\n      emailId\n      profileUrl\n      uniqueUrl\n      employeeId\n    }\n  }\n"])));