var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13;
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import { gql } from "@apollo/client";
export var BILLING_DETAILS_QUERY = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  query billingDetails($organisationId: String!) {\n    billingDetails(organisationId: $organisationId) {\n      planData {\n        planName\n        planId\n        trialPeriod\n        price\n        createdAt\n        storage\n        validityEnd\n        validityPeriod\n        trialStart\n        trialEnd\n        isTrial\n        trialPeriodDays\n        planStatus\n        stoppedReason\n        storageUsedPercentage\n        trialStatus\n        planType\n        # paymentPlanId\n        # transactionStatus\n        userCount\n        failedPlanEnd\n        beforePlanEndAlert\n        paymentType\n        transactionStatus\n      }\n      planFeatureDetails {\n        featureName\n        featureValue\n      }\n    }\n  }\n"])));
export var PLAN_DETAILS_QUERY = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  query planDetails($organisationId: String!) {\n    planDetails(organisationId: $organisationId) {\n      planData {\n        planName\n        planId\n        trialPeriod\n        price\n        createdAt\n        storage\n        priceInr\n        priceUsd\n        priceYearlyInr\n        priceYearlyUsd\n      }\n      planFeatureDetails {\n        featureName\n        featureValue\n      }\n      storageInPercentage\n    }\n  }\n"])));
export var INVOICE_HISTORY_QUERY = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  query InvoiceHistory($userId: String!, $organisationId: String!) {\n    invoiceHistory(userId: $userId, organisationId: $organisationId) {\n      date\n      amount\n      invoiceId\n      transactionId\n      downloadUrl\n      currencyCode\n    }\n  }\n"])));
export var BILLING_UPDATE_MUTATION = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  mutation updateBilling(\n    $organisationId: String!\n    $planId: String!\n    $userId: String!\n    $isTrial: Boolean!\n    $projectIds: [String]\n    $downgradeReason: String\n  ) {\n    updateBilling(\n      organisationId: $organisationId\n      planId: $planId\n      userId: $userId\n      isTrial: $isTrial\n      projectIds: $projectIds\n      downgradeReason: $downgradeReason\n    )\n  }\n"])));
export var GENERATE_CHECKOUT_NEW_URL_MUTATION = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  mutation GenerateCheckoutNewUrl(\n    $id: String!\n    $first_name: String!\n    $last_name: String!\n    $email: String!\n    $currency_code: String!\n    $organisationId: String!\n    $userCount: Int\n    $type: String\n    $planType: String\n    $planId: String!\n  ) {\n    generateCheckoutNewUrl(\n      id: $id\n      first_name: $first_name\n      last_name: $last_name\n      email: $email\n      currency_code: $currency_code\n      organisationId: $organisationId\n      userCount: $userCount\n      type: $type\n      planType: $planType\n      planId: $planId\n    ) {\n      id\n      type\n      url\n      state\n      embed\n      created_at\n      expires_at\n      object\n      updated_at\n      resource_version\n    }\n  }\n"])));
export var ADD_PAYMENT_SOURCE_MUTATION = gql(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  mutation AddPaymentSource(\n    $userId: String!\n    $cardNumber: String!\n    $cvv: String!\n    $expiryYear: Int!\n    $expiryMonth: Int!\n  ) {\n    addPaymentSource(\n      userId: $userId\n      cardNumber: $cardNumber\n      cvv: $cvv\n      expiryYear: $expiryYear\n      expiryMonth: $expiryMonth\n    )\n  }\n"])));
export var GET_CARD_DETAILS_QUERY = gql(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  query GetCardDetails($customerId: String!) {\n    getCardDetails(customerId: $customerId) {\n      status\n      gateway\n      gateway_account_id\n      iin\n      last4\n      card_type\n      funding_type\n      expiry_month\n      expiry_year\n      created_at\n      updated_at\n      ip_address\n      resource_version\n      object\n      masked_number\n      customer_id\n      payment_source_id\n    }\n  }\n"])));
export var DELETE_CARD_DETAILS_MUTATION = gql(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  mutation DeleteCardDetails($paymentSourceId: String!) {\n    deleteCardDetails(paymentSourceId: $paymentSourceId)\n  }\n"])));
export var UPDATE_TRANSACTION_DEATILS_MUTATION = gql(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  mutation UpdateTransactionDeatils(\n    $customerId: String!\n    $organisationId: String!\n    $planId: String!\n    $userId: String!\n    $planType: String!\n  ) {\n    updateTransactionDeatils(\n      customerId: $customerId\n      organisationId: $organisationId\n      planId: $planId\n      userId: $userId\n      planType: $planType\n    )\n  }\n"])));
export var ADD_PAYMENT_DETAILS_MUTATION = gql(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  mutation AddPaymentDetails($paymentSourceId: String!, $organisationId: String!, $planId: String!, $userId: String!) {\n    addPaymentDetails(\n      paymentSourceId: $paymentSourceId\n      organisationId: $organisationId\n      planId: $planId\n      userId: $userId\n    )\n  }\n"])));
export var PROJECT_LIST_QUERY = gql(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  query projectList($organisationId: String!, $url: String!, $role: String!) {\n    projectList(organisationId: $organisationId, url: $url, role: $role) {\n      projectId\n      projectName\n    }\n  }\n"])));
export var UPDATE_USER_CHARGEBEE_CARD_DETAILS_MUTATION = gql(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n  mutation updateUserChargebeeCardDetails($tokenId: String!, $organisationId: String!) {\n    updateUserChargebeeCardDetails(tokenId: $tokenId, organisationId: $organisationId)\n  }\n"])));

// export const UPDATE_BILLING_SETTINGS_MUTATION = gql`
//   mutation UpdateBillingSettings($reminderMail: Boolean!, $confirmationMail: Boolean!, $organisationId: String!) {
//     updateBillingSettings(
//       reminderMail: $reminderMail
//       confirmationMail: $confirmationMail
//       organisationId: $organisationId
//     )
//   }
// `;

// export const BILLING_SETTINGS_QUERY = gql`
//   query BillingSettings($organisationId: String!) {
//     billingSettings(organisationId: $organisationId) {
//       reminderMail
//       confirmationMail
//     }
//   }
// `;

// export const CHECKOUT_ONE_TIME_PAYMENT_MUTATION = gql`
//   mutation CheckoutOneTimePayment(
//     $id: String!
//     $first_name: String!
//     $last_name: String!
//     $email: String!
//     $currency_code: String!
//     $organisationId: String!
//     $planId: String!
//   ) {
//     checkoutOneTimePayment(
//       id: $id
//       first_name: $first_name
//       last_name: $last_name
//       email: $email
//       currency_code: $currency_code
//       organisationId: $organisationId
//       planId: $planId
//     ) {
//       id
//       type
//       url
//       state
//       embed
//       created_at
//       expires_at
//       object
//       updated_at
//       resource_version
//     }
//   }
// `;

export var GENERATE_PAYMENT_METHOD_NEW_URL_MUTATION = gql(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n  mutation generatePaymentMethodNewUrl($id: String!, $organisationId: String!) {\n    generatePaymentMethodNewUrl(id: $id, organisationId: $organisationId) {\n      id\n      type\n      url\n      state\n      embed\n      created_at\n      expires_at\n      object\n      updated_at\n      resource_version\n    }\n  }\n"])));