/*  working day list */
export var WorkingDaysList = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
export var currenciesList = [{
  label: "United States Dollar ($)",
  value: "USD"
}, {
  label: "Euro (€)",
  value: "EUR"
}, {
  label: "British Pound Sterling (£)",
  value: "GBP"
}, {
  label: "Indian Rupee (₹)",
  value: "INR"
}, {
  label: "Brazilian Real (R$)",
  value: "BRL"
}, {
  label: "South African Rand (R)",
  value: "ZAR"
}, {
  label: "Swedish Krona (kr)",
  value: "SEK"
}, {
  label: "Nigerian Naira (₦)",
  value: "NGN"
}, {
  label: "Philippine Peso (₱)",
  value: "PHP"
}, {
  label: "Malaysian Ringgit (RM)",
  value: "MYR"
}, {
  label: "Singapore Dollar (S$)",
  value: "SGD"
}];

/*  Minimum work hours list */
export var expectedWorkingHourData = [{
  mins: "15",
  hours: "15 mins",
  secs: "900"
}, {
  mins: "30",
  hours: "30 mins",
  secs: "1800"
}, {
  mins: "45",
  hours: "45 mins",
  secs: "2700"
}, {
  mins: "60",
  hours: "1 hour",
  secs: "3600"
}, {
  mins: "75",
  hours: "1 hour 15 mins",
  secs: "4500"
}, {
  mins: "90",
  hours: "1 hour 30 mins",
  secs: "5400"
}, {
  mins: "105",
  hours: "1 hour 45 mins",
  secs: "6300"
}, {
  mins: "120",
  hours: "2 hour",
  secs: "7200"
}, {
  mins: "135",
  hours: "2 hour 15 mins",
  secs: "8100"
}, {
  mins: "150",
  hours: "2 hour 30 mins",
  secs: "9000"
}, {
  mins: "165",
  hours: "2 hour 45 mins",
  secs: "9900"
}, {
  mins: "180",
  hours: "3 hour",
  secs: "10800"
}, {
  mins: "195",
  hours: "3 hour 15 mins",
  secs: "11700"
}, {
  mins: "210",
  hours: "3 hour 30 mins",
  secs: "12600"
}, {
  mins: "225",
  hours: "3 hour 45 mins",
  secs: "13500"
}, {
  mins: "240",
  hours: "4 hour",
  secs: "14400"
}, {
  mins: "255",
  hours: "4 hour 15 mins",
  secs: "15300"
}, {
  mins: "270",
  hours: "4 hour 30 mins",
  secs: "16200"
}, {
  mins: "285",
  hours: "4 hour 45 mins",
  secs: "17100"
}, {
  mins: "300",
  hours: "5 hour",
  secs: "18000"
}, {
  mins: "315",
  hours: "5 hour 15 mins",
  secs: "18900"
}, {
  mins: "330",
  hours: "5 hour 30 mins",
  secs: "19800"
}, {
  mins: "345",
  hours: "5 hour 45 mins",
  secs: "20700"
}, {
  mins: "360",
  hours: "6 hour",
  secs: "21600"
}, {
  mins: "375",
  hours: "6 hour 15 mins",
  secs: "22500"
}, {
  mins: "390",
  hours: "6 hour 30 mins",
  secs: "23400"
}, {
  mins: "405",
  hours: "6 hour 45 mins",
  secs: "24300"
}, {
  mins: "420",
  hours: "7 hour",
  secs: "25200"
}, {
  mins: "435",
  hours: "7 hour 15 mins",
  secs: "26100"
}, {
  mins: "450",
  hours: "7 hour 30 mins",
  secs: "27000"
}, {
  mins: "465",
  hours: "7 hour 45 mins",
  secs: "27900"
}, {
  mins: "480",
  hours: "8 hour",
  secs: "28800"
}, {
  mins: "495",
  hours: "8 hour 15 mins",
  secs: "29700"
}, {
  mins: "510",
  hours: "8 hour 30 mins",
  secs: "30600"
}, {
  mins: "525",
  hours: "8 hour 45 mins",
  secs: "31500"
}, {
  mins: "540",
  hours: "9 hour",
  secs: "32400"
}, {
  mins: "555",
  hours: "9 hour 15 mins",
  secs: "33300"
}, {
  mins: "570",
  hours: "9 hour 30 mins",
  secs: "34200"
}, {
  mins: "585",
  hours: "9 hour 45 mins",
  secs: "35100"
}, {
  mins: "600",
  hours: "10 hour",
  secs: "36000"
}, {
  mins: "615",
  hours: "10 hour 15 mins",
  secs: "36900"
}, {
  mins: "630",
  hours: "10 hour 30 mins",
  secs: "37800"
}, {
  mins: "645",
  hours: "10 hour 45 mins",
  secs: "38700"
}, {
  mins: "660",
  hours: "11 hour",
  secs: "39600"
}, {
  mins: "675",
  hours: "11 hour 15 mins",
  secs: "40500"
}, {
  mins: "690",
  hours: "11 hour 30 mins",
  secs: "41400"
}, {
  mins: "705",
  hours: "11 hour 45 mins",
  secs: "42300"
}, {
  mins: "720",
  hours: "12 hour",
  secs: "43200"
}, {
  mins: "735",
  hours: "11 hour 15 mins",
  secs: "44100"
}, {
  mins: "750",
  hours: "12 hour 30 mins",
  secs: "45000"
}, {
  mins: "765",
  hours: "12 hour 45 mins",
  secs: "45900"
}, {
  mins: "780",
  hours: "13 hour",
  secs: "46800"
}, {
  mins: "795",
  hours: "13 hour 15 mins",
  secs: "47700"
}, {
  mins: "810",
  hours: "13 hour 30 mins",
  secs: "48600"
}, {
  mins: "825",
  hours: "13 hour 45 mins",
  secs: "49500"
}, {
  mins: "840",
  hours: "14 hour",
  secs: "50400"
}, {
  mins: "855",
  hours: "14 hour 15 mins",
  secs: "51300"
}, {
  mins: "870",
  hours: "14 hour 30 mins",
  secs: "52200"
}, {
  mins: "885",
  hours: "14 hour 45 mins",
  secs: "53100"
}, {
  mins: "900",
  hours: "15 hour",
  secs: "54000"
}, {
  mins: "915",
  hours: "15 hour 15 mins",
  secs: "54900"
}, {
  mins: "930",
  hours: "15 hour 30 mins",
  secs: "55800"
}, {
  mins: "945",
  hours: "15 hour 45 mins",
  secs: "56700"
}];
/*  Minimum productive work hours list */
export var productiveWorkingHourData = [{
  mins: "15",
  hours: "15 mins"
}, {
  mins: "30",
  hours: "30 mins"
}, {
  mins: "45",
  hours: "45 mins"
}, {
  mins: "60",
  hours: "1 hour"
}, {
  mins: "75",
  hours: "1 hour 15 mins"
}, {
  mins: "90",
  hours: "1 hour 30 mins"
}, {
  mins: "105",
  hours: "1 hour 45 mins"
}, {
  mins: "120",
  hours: "2 hour"
}, {
  mins: "135",
  hours: "2 hour 15 mins"
}, {
  mins: "150",
  hours: "2 hour 30 mins"
}, {
  mins: "165",
  hours: "2 hour 45 mins"
}, {
  mins: "180",
  hours: "3 hour"
}, {
  mins: "195",
  hours: "3 hour 15 mins"
}, {
  mins: "210",
  hours: "3 hour 30 mins"
}, {
  mins: "225",
  hours: "3 hour 45 mins"
}, {
  mins: "240",
  hours: "4 hour"
}, {
  mins: "255",
  hours: "4 hour 15 mins"
}, {
  mins: "270",
  hours: "4 hour 30 mins"
}, {
  mins: "285",
  hours: "4 hour 45 mins"
}, {
  mins: "300",
  hours: "5 hour"
}, {
  mins: "315",
  hours: "5 hour 15 mins"
}, {
  mins: "330",
  hours: "5 hour 30 mins"
}, {
  mins: "345",
  hours: "5 hour 45 mins"
}, {
  mins: "360",
  hours: "6 hour"
}, {
  mins: "375",
  hours: "6 hour 15 mins"
}, {
  mins: "390",
  hours: "6 hour 30 mins"
}, {
  mins: "405",
  hours: "6 hour 45 mins"
}, {
  mins: "420",
  hours: "7 hour"
}, {
  mins: "435",
  hours: "7 hour 15 mins"
}, {
  mins: "450",
  hours: "7 hour 30 mins"
}, {
  mins: "465",
  hours: "7 hour 45 mins"
}, {
  mins: "480",
  hours: "8 hour"
}, {
  mins: "495",
  hours: "8 hour 15 mins"
}, {
  mins: "510",
  hours: "8 hour 30 mins"
}, {
  mins: "525",
  hours: "8 hour 45 mins"
}, {
  mins: "540",
  hours: "9 hour"
}, {
  mins: "555",
  hours: "9 hour 15 mins"
}, {
  mins: "570",
  hours: "9 hour 30 mins"
}, {
  mins: "585",
  hours: "9 hour 45 mins"
}, {
  mins: "600",
  hours: "10 hour"
}, {
  mins: "615",
  hours: "10 hour 15 mins"
}, {
  mins: "630",
  hours: "10 hour 30 mins"
}, {
  mins: "645",
  hours: "10 hour 45 mins"
}, {
  mins: "660",
  hours: "11 hour"
}, {
  mins: "675",
  hours: "11 hour 15 mins"
}, {
  mins: "690",
  hours: "11 hour 30 mins"
}, {
  mins: "705",
  hours: "11 hour 45 mins"
}, {
  mins: "720",
  hours: "12 hour"
}, {
  mins: "735",
  hours: "11 hour 15 mins"
}, {
  mins: "750",
  hours: "12 hour 30 mins"
}, {
  mins: "765",
  hours: "12 hour 45 mins"
}, {
  mins: "780",
  hours: "13 hour"
}, {
  mins: "795",
  hours: "13 hour 15 mins"
}, {
  mins: "810",
  hours: "13 hour 30 mins"
}, {
  mins: "825",
  hours: "13 hour 45 mins"
}, {
  mins: "840",
  hours: "14 hour"
}, {
  mins: "855",
  hours: "14 hour 15 mins"
}, {
  mins: "870",
  hours: "14 hour 30 mins"
}, {
  mins: "885",
  hours: "14 hour 45 mins"
}, {
  mins: "900",
  hours: "15 hour"
}, {
  mins: "915",
  hours: "15 hour 15 mins"
}, {
  mins: "930",
  hours: "15 hour 30 mins"
}, {
  mins: "945",
  hours: "15 hour 45 mins"
}];
/*  for ref extra list */
export var timeData = [{
  time: "01:00"
}, {
  time: "01:05"
}, {
  time: "01:10"
}, {
  time: "01:15"
}, {
  time: "01:20"
}, {
  time: "01:25"
}, {
  time: "01:30"
}, {
  time: "01:35"
}, {
  time: "01:40"
}, {
  time: "01:45"
}, {
  time: "01:50"
}, {
  time: "01:55"
}, {
  time: "02:00"
}, {
  time: "02:05"
}, {
  time: "02:10"
}, {
  time: "02:15"
}, {
  time: "02:20"
}, {
  time: "02:25"
}, {
  time: "02:30"
}, {
  time: "02:35"
}, {
  time: "02:40"
}, {
  time: "02:45"
}, {
  time: "02:50"
}, {
  time: "02:55"
}, {
  time: "03:00"
}, {
  time: "03:05"
}, {
  time: "03:10"
}, {
  time: "03:15"
}, {
  time: "03:20"
}, {
  time: "03:25"
}, {
  time: "03:30"
}, {
  time: "03:35"
}, {
  time: "03:40"
}, {
  time: "03:45"
}, {
  time: "03:50"
}, {
  time: "03:55"
}, {
  time: "04:00"
}, {
  time: "04:05"
}, {
  time: "04:10"
}, {
  time: "04:15"
}, {
  time: "04:20"
}, {
  time: "04:25"
}, {
  time: "04:30"
}, {
  time: "04:35"
}, {
  time: "04:40"
}, {
  time: "04:45"
}, {
  time: "04:50"
}, {
  time: "04:55"
}, {
  time: "05:00"
}, {
  time: "05:05"
}, {
  time: "05:10"
}, {
  time: "05:15"
}, {
  time: "05:20"
}, {
  time: "05:25"
}, {
  time: "05:30"
}, {
  time: "05:35"
}, {
  time: "05:40"
}, {
  time: "05:45"
}, {
  time: "05:50"
}, {
  time: "05:55"
}, {
  time: "06:00"
}, {
  time: "06:05"
}, {
  time: "06:10"
}, {
  time: "06:15"
}, {
  time: "06:20"
}, {
  time: "06:25"
}, {
  time: "06:30"
}, {
  time: "06:35"
}, {
  time: "06:40"
}, {
  time: "06:45"
}, {
  time: "06:50"
}, {
  time: "06:55"
}, {
  time: "07:00"
}, {
  time: "07:05"
}, {
  time: "07:10"
}, {
  time: "07:15"
}, {
  time: "07:20"
}, {
  time: "07:25"
}, {
  time: "07:30"
}, {
  time: "07:35"
}, {
  time: "07:40"
}, {
  time: "07:45"
}, {
  time: "07:50"
}, {
  time: "07:55"
}, {
  time: "08:00"
}, {
  time: "08:05"
}, {
  time: "08:10"
}, {
  time: "08:15"
}, {
  time: "08:20"
}, {
  time: "08:25"
}, {
  time: "08:30"
}, {
  time: "08:35"
}, {
  time: "08:40"
}, {
  time: "08:45"
}, {
  time: "08:50"
}, {
  time: "08:55"
}, {
  time: "09:00"
}, {
  time: "09:05"
}, {
  time: "09:10"
}, {
  time: "09:15"
}, {
  time: "09:20"
}, {
  time: "09:25"
}, {
  time: "09:30"
}, {
  time: "09:35"
}, {
  time: "09:40"
}, {
  time: "09:45"
}, {
  time: "09:50"
}, {
  time: "09:55"
}, {
  time: "10:00"
}, {
  time: "10:05"
}, {
  time: "10:10"
}, {
  time: "10:15"
}, {
  time: "10:20"
}, {
  time: "10:25"
}, {
  time: "01:30"
}, {
  time: "10:35"
}, {
  time: "10:40"
}, {
  time: "10:45"
}, {
  time: "10:50"
}, {
  time: "10:55"
}, {
  time: "11:00"
}, {
  time: "11:05"
}, {
  time: "11:10"
}, {
  time: "11:15"
}, {
  time: "11:20"
}, {
  time: "11:25"
}, {
  time: "11:30"
}, {
  time: "11:35"
}, {
  time: "11:40"
}, {
  time: "11:45"
}, {
  time: "11:50"
}, {
  time: "11:55"
}, {
  time: "12:00"
}, {
  time: "12:05"
}, {
  time: "12:10"
}, {
  time: "12:15"
}, {
  time: "12:20"
}, {
  time: "12:25"
}, {
  time: "12:30"
}, {
  time: "12:35"
}, {
  time: "12:40"
}, {
  time: "12:45"
}, {
  time: "12:50"
}, {
  time: "12:55"
}, {
  time: "13:00"
}, {
  time: "13:05"
}, {
  time: "13:10"
}, {
  time: "13:15"
}, {
  time: "13:20"
}, {
  time: "13:25"
}, {
  time: "13:30"
}, {
  time: "13:35"
}, {
  time: "13:40"
}, {
  time: "13:45"
}, {
  time: "13:50"
}, {
  time: "13:55"
}, {
  time: "14:00"
}, {
  time: "14:05"
}, {
  time: "14:10"
}, {
  time: "14:15"
}, {
  time: "14:20"
}, {
  time: "14:25"
}, {
  time: "14:30"
}, {
  time: "14:35"
}, {
  time: "14:40"
}, {
  time: "14:45"
}, {
  time: "14:50"
}, {
  time: "14:55"
}, {
  time: "15:00"
}, {
  time: "15:05"
}, {
  time: "15:10"
}, {
  time: "15:15"
}, {
  time: "15:20"
}, {
  time: "15:25"
}, {
  time: "15:30"
}, {
  time: "15:35"
}, {
  time: "15:40"
}, {
  time: "15:45"
}, {
  time: "15:50"
}, {
  time: "15:55"
}, {
  time: "16:00"
}, {
  time: "16:05"
}, {
  time: "16:10"
}, {
  time: "16:15"
}, {
  time: "16:20"
}, {
  time: "16:25"
}, {
  time: "16:30"
}, {
  time: "16:35"
}, {
  time: "16:40"
}, {
  time: "16:45"
}, {
  time: "16:50"
}, {
  time: "16:55"
}, {
  time: "17:00"
}, {
  time: "17:05"
}, {
  time: "17:10"
}, {
  time: "17:15"
}, {
  time: "17:20"
}, {
  time: "17:25"
}, {
  time: "17:30"
}, {
  time: "17:35"
}, {
  time: "17:40"
}, {
  time: "17:45"
}, {
  time: "17:50"
}, {
  time: "17:55"
}, {
  time: "18:00"
}, {
  time: "18:05"
}, {
  time: "18:10"
}, {
  time: "18:15"
}, {
  time: "18:20"
}, {
  time: "18:25"
}, {
  time: "18:30"
}, {
  time: "18:35"
}, {
  time: "18:40"
}, {
  time: "18:45"
}, {
  time: "18:50"
}, {
  time: "18:55"
}, {
  time: "19:00"
}, {
  time: "19:05"
}, {
  time: "19:10"
}, {
  time: "19:15"
}, {
  time: "19:20"
}, {
  time: "19:25"
}, {
  time: "19:30"
}, {
  time: "19:35"
}, {
  time: "19:40"
}, {
  time: "19:45"
}, {
  time: "19:50"
}, {
  time: "19:55"
}, {
  time: "20:00"
}, {
  time: "20:05"
}, {
  time: "20:10"
}, {
  time: "20:15"
}, {
  time: "20:20"
}, {
  time: "20:25"
}, {
  time: "20:30"
}, {
  time: "20:35"
}, {
  time: "20:40"
}, {
  time: "20:45"
}, {
  time: "20:50"
}, {
  time: "20:55"
}, {
  time: "21:00"
}, {
  time: "21:00"
}, {
  time: "21:05"
}, {
  time: "21:10"
}, {
  time: "21:15"
}, {
  time: "21:20"
}, {
  time: "21:25"
}, {
  time: "21:30"
}, {
  time: "21:35"
}, {
  time: "21:40"
}, {
  time: "21:45"
}, {
  time: "21:50"
}, {
  time: "21:55"
}, {
  time: "22:00"
}, {
  time: "22:05"
}, {
  time: "22:10"
}, {
  time: "22:15"
}, {
  time: "22:20"
}, {
  time: "22:25"
}, {
  time: "22:30"
}, {
  time: "22:35"
}, {
  time: "22:40"
}, {
  time: "22:45"
}, {
  time: "22:50"
}, {
  time: "22:55"
}, {
  time: "23:00"
}, {
  time: "23:05"
}, {
  time: "23:10"
}, {
  time: "23:15"
}, {
  time: "23:20"
}, {
  time: "23:25"
}, {
  time: "23:30"
}, {
  time: "23:35"
}, {
  time: "23:40"
}, {
  time: "23:45"
}, {
  time: "23:50"
}, {
  time: "23:55"
}, {
  time: "24:00"
}];

/*  Screen capture interval list */
export var ScreenshotListFreePlan = [{
  mins: "Every 5 mins",
  minsvalue: "5"
}, {
  mins: "Every 1 min",
  minsvalue: "1"
}, {
  mins: "Every 2 mins",
  minsvalue: "2"
}, {
  mins: "Every 3 mins",
  minsvalue: "3"
}, {
  mins: "Every 4 mins",
  minsvalue: "4"
}, {
  mins: "Every 5 min",
  minsvalue: "5"
}, {
  mins: "Every 6 mins",
  minsvalue: "6"
}, {
  mins: "Every 7 mins",
  minsvalue: "7"
}, {
  mins: "Every 8 mins",
  minsvalue: "8"
}, {
  mins: "Every 9 mins",
  minsvalue: "9"
}, {
  mins: "Every 10 mins",
  minsvalue: "10"
}, {
  mins: "Every 11 mins",
  minsvalue: "11"
}, {
  mins: "Every 12 mins",
  minsvalue: "12"
}, {
  mins: "Every 13 mins",
  minsvalue: "13"
}, {
  mins: "Every 14 mins",
  minsvalue: "14"
}, {
  mins: "Every 15 mins",
  minsvalue: "15"
}, {
  mins: "Every 16 mins",
  minsvalue: "16"
}, {
  mins: "Every 17 mins",
  minsvalue: "17"
}, {
  mins: "Every 18 mins",
  minsvalue: "18"
}, {
  mins: "Every 19 mins",
  minsvalue: "19"
}, {
  mins: "Every 20 mins",
  minsvalue: "20"
}, {
  mins: "Every 21 mins",
  minsvalue: "21"
}, {
  mins: "Every 22 mins",
  minsvalue: "22"
}, {
  mins: "Every 23 mins",
  minsvalue: "23"
}, {
  mins: "Every 24 mins",
  minsvalue: "24"
}, {
  mins: "Every 25 mins",
  minsvalue: "25"
}, {
  mins: "Every 26 mins",
  minsvalue: "26"
}, {
  mins: "Every 27 mins",
  minsvalue: "27"
}, {
  mins: "Every 28 mins",
  minsvalue: "28"
}, {
  mins: "Every 29 mins",
  minsvalue: "29"
}, {
  mins: "Every 30 mins",
  minsvalue: "30"
}, {
  mins: "Every 31 mins",
  minsvalue: "31"
}, {
  mins: "Every 32 mins",
  minsvalue: "32"
}, {
  mins: "Every 33 mins",
  minsvalue: "33"
}, {
  mins: "Every 34 mins",
  minsvalue: "34"
}, {
  mins: "Every 35 mins",
  minsvalue: "35"
}, {
  mins: "Every 36 mins",
  minsvalue: "36"
}, {
  mins: "Every 37 mins",
  minsvalue: "37"
}, {
  mins: "Every 38 mins",
  minsvalue: "38"
}, {
  mins: "Every 39 mins",
  minsvalue: "39"
}, {
  mins: "Every 40 mins",
  minsvalue: "40"
}, {
  mins: "Every 41 mins",
  minsvalue: "41"
}, {
  mins: "Every 42 mins",
  minsvalue: "42"
}, {
  mins: "Every 43 mins",
  minsvalue: "43"
}, {
  mins: "Every 44 mins",
  minsvalue: "44"
}, {
  mins: "Every 45 mins",
  minsvalue: "45"
}, {
  mins: "Every 46 mins",
  minsvalue: "46"
}, {
  mins: "Every 47 mins",
  minsvalue: "47"
}, {
  mins: "Every 48 mins",
  minsvalue: "48"
}, {
  mins: "Every 49 mins",
  minsvalue: "49"
}, {
  mins: "Every 50 mins",
  minsvalue: "50"
}, {
  mins: "Every 51 mins",
  minsvalue: "51"
}, {
  mins: "Every 52 mins",
  minsvalue: "52"
}, {
  mins: "Every 53 mins",
  minsvalue: "53"
}, {
  mins: "Every 54 mins",
  minsvalue: "54"
}, {
  mins: "Every 55 mins",
  minsvalue: "55"
}, {
  mins: "Every 56 mins",
  minsvalue: "56"
}, {
  mins: "Every 57 mins",
  minsvalue: "57"
}, {
  mins: "Every 58 mins",
  minsvalue: "58"
}, {
  mins: "Every 59 mins",
  minsvalue: "59"
}, {
  mins: "Every 1 hour",
  minsvalue: "60"
}];

/*  Screen capture interval list */
export var ScreenshotList = [{
  mins: "Every 1 min",
  minsvalue: "1"
}, {
  mins: "Every 2 mins",
  minsvalue: "2"
}, {
  mins: "Every 3 mins",
  minsvalue: "3"
}, {
  mins: "Every 4 mins",
  minsvalue: "4"
}, {
  mins: "Every 5 min",
  minsvalue: "5"
}, {
  mins: "Every 6 mins",
  minsvalue: "6"
}, {
  mins: "Every 7 mins",
  minsvalue: "7"
}, {
  mins: "Every 8 mins",
  minsvalue: "8"
}, {
  mins: "Every 9 mins",
  minsvalue: "9"
}, {
  mins: "Every 10 mins",
  minsvalue: "10"
}, {
  mins: "Every 11 mins",
  minsvalue: "11"
}, {
  mins: "Every 12 mins",
  minsvalue: "12"
}, {
  mins: "Every 13 mins",
  minsvalue: "13"
}, {
  mins: "Every 14 mins",
  minsvalue: "14"
}, {
  mins: "Every 15 mins",
  minsvalue: "15"
}, {
  mins: "Every 16 mins",
  minsvalue: "16"
}, {
  mins: "Every 17 mins",
  minsvalue: "17"
}, {
  mins: "Every 18 mins",
  minsvalue: "18"
}, {
  mins: "Every 19 mins",
  minsvalue: "19"
}, {
  mins: "Every 20 mins",
  minsvalue: "20"
}, {
  mins: "Every 21 mins",
  minsvalue: "21"
}, {
  mins: "Every 22 mins",
  minsvalue: "22"
}, {
  mins: "Every 23 mins",
  minsvalue: "23"
}, {
  mins: "Every 24 mins",
  minsvalue: "24"
}, {
  mins: "Every 25 mins",
  minsvalue: "25"
}, {
  mins: "Every 26 mins",
  minsvalue: "26"
}, {
  mins: "Every 27 mins",
  minsvalue: "27"
}, {
  mins: "Every 28 mins",
  minsvalue: "28"
}, {
  mins: "Every 29 mins",
  minsvalue: "29"
}, {
  mins: "Every 30 mins",
  minsvalue: "30"
}, {
  mins: "Every 31 mins",
  minsvalue: "31"
}, {
  mins: "Every 32 mins",
  minsvalue: "32"
}, {
  mins: "Every 33 mins",
  minsvalue: "33"
}, {
  mins: "Every 34 mins",
  minsvalue: "34"
}, {
  mins: "Every 35 mins",
  minsvalue: "35"
}, {
  mins: "Every 36 mins",
  minsvalue: "36"
}, {
  mins: "Every 37 mins",
  minsvalue: "37"
}, {
  mins: "Every 38 mins",
  minsvalue: "38"
}, {
  mins: "Every 39 mins",
  minsvalue: "39"
}, {
  mins: "Every 40 mins",
  minsvalue: "40"
}, {
  mins: "Every 41 mins",
  minsvalue: "41"
}, {
  mins: "Every 42 mins",
  minsvalue: "42"
}, {
  mins: "Every 43 mins",
  minsvalue: "43"
}, {
  mins: "Every 44 mins",
  minsvalue: "44"
}, {
  mins: "Every 45 mins",
  minsvalue: "45"
}, {
  mins: "Every 46 mins",
  minsvalue: "46"
}, {
  mins: "Every 47 mins",
  minsvalue: "47"
}, {
  mins: "Every 48 mins",
  minsvalue: "48"
}, {
  mins: "Every 49 mins",
  minsvalue: "49"
}, {
  mins: "Every 50 mins",
  minsvalue: "50"
}, {
  mins: "Every 51 mins",
  minsvalue: "51"
}, {
  mins: "Every 52 mins",
  minsvalue: "52"
}, {
  mins: "Every 53 mins",
  minsvalue: "53"
}, {
  mins: "Every 54 mins",
  minsvalue: "54"
}, {
  mins: "Every 55 mins",
  minsvalue: "55"
}, {
  mins: "Every 56 mins",
  minsvalue: "56"
}, {
  mins: "Every 57 mins",
  minsvalue: "57"
}, {
  mins: "Every 58 mins",
  minsvalue: "58"
}, {
  mins: "Every 59 mins",
  minsvalue: "59"
}, {
  mins: "Every 1 hour",
  minsvalue: "60"
}];
/*  idleMinutes */
export var IdleMinutesList = [{
  mins: " 5 mins",
  minsvalue: "5"
}, {
  mins: " 10 mins",
  minsvalue: "10"
}, {
  mins: " 15 mins",
  minsvalue: "15"
}, {
  mins: " 30 mins",
  minsvalue: "30"
}, {
  mins: " 45 mins",
  minsvalue: "45"
}, {
  mins: " 1 hour",
  minsvalue: "60"
}];
/*  Idle TIme (seconds) list */
export var IdelTimeList = [{
  secs: "60 Secs",
  secsvalue: "60"
}, {
  secs: "120 Secs",
  secsvalue: "120"
}, {
  secs: "180 Secs",
  secsvalue: "180"
}, {
  secs: "240 Secs",
  secsvalue: "180"
}, {
  secs: "300 secs",
  secsvalue: "300"
}];
/*  Organisation timezone list */
// export const timezoneList = [
// "Europe/Andorra",
// "Asia/Dubai",
// "Asia/Kabul",
// "Europe/Tirane",
// "Asia/Yerevan",
// "Antarctica/Casey",
// "Antarctica/Davis",
// "Antarctica/DumontDUrville",
// "Antarctica/Mawson",
// "Antarctica/Palmer",
// "Antarctica/Rothera",
// "Antarctica/Syowa",
// "Antarctica/Troll",
// "Antarctica/Vostok",
// "America/Argentina/Buenos_Aires",
// "America/Argentina/Cordoba",
// "America/Argentina/Salta",
// "America/Argentina/Jujuy",
// "America/Argentina/Tucuman",
// "America/Argentina/Catamarca",
// "America/Argentina/La_Rioja",
// "America/Argentina/San_Juan",
// "America/Argentina/Mendoza",
// "America/Argentina/San_Luis",
// "America/Argentina/Rio_Gallegos",
// "America/Argentina/Ushuaia",
// "Pacific/Pago_Pago",
// "Europe/Vienna",
// "Australia/Lord_Howe",
// "Antarctica/Macquarie",
// "Australia/Hobart",
// "Australia/Currie",
// "Australia/Melbourne",
// "Australia/Sydney",
// "Australia/Broken_Hill",
// "Australia/Brisbane",
// "Australia/Lindeman",
// "Australia/Adelaide",
// "Australia/Darwin",
// "Australia/Perth",
// "Australia/Eucla",
// "Asia/Baku",
// "America/Barbados",
// "Asia/Dhaka",
// "Europe/Brussels",
// "Europe/Sofia",
// "Atlantic/Bermuda",
// "Asia/Brunei",
// "America/La_Paz",
// "America/Noronha",
// "America/Belem",
// "America/Fortaleza",
// "America/Recife",
// "America/Araguaina",
// "America/Maceio",
// "America/Bahia",
// "America/Sao_Paulo",
// "America/Campo_Grande",
// "America/Cuiaba",
// "America/Santarem",
// "America/Porto_Velho",
// "America/Boa_Vista",
// "America/Manaus",
// "America/Eirunepe",
// "America/Rio_Branco",
// "America/Nassau",
// "Asia/Thimphu",
// "Europe/Minsk",
// "America/Belize",
// "America/St_Johns",
// "America/Halifax",
// "America/Glace_Bay",
// "America/Moncton",
// "America/Goose_Bay",
// "America/Blanc-Sablon",
// "America/Toronto",
// "America/Nipigon",
// "America/Thunder_Bay",
// "America/Iqaluit",
// "America/Pangnirtung",
// "America/Atikokan",
// "America/Winnipeg",
// "America/Rainy_River",
// "America/Resolute",
// "America/Rankin_Inlet",
// "America/Regina",
// "America/Swift_Current",
// "America/Edmonton",
// "America/Cambridge_Bay",
// "America/Yellowknife",
// "America/Inuvik",
// "America/Creston",
// "America/Dawson_Creek",
// "America/Fort_Nelson",
// "America/Vancouver",
// "America/Whitehorse",
// "America/Dawson",
// "Indian/Cocos",
// "Europe/Zurich",
// "Africa/Abidjan",
// "Pacific/Rarotonga",
// "America/Santiago",
// "America/Punta_Arenas",
// "Pacific/Easter",
// "Asia/Shanghai",
// "Asia/Urumqi",
// "America/Bogota",
// "America/Costa_Rica",
// "America/Havana",
// "Atlantic/Cape_Verde",
// "America/Curacao",
// "Indian/Christmas",
// "Asia/Nicosia",
// "Asia/Famagusta",
// "Europe/Prague",
// "Europe/Berlin",
// "Europe/Copenhagen",
// "America/Santo_Domingo",
// "Africa/Algiers",
// "America/Guayaquil",
// "Pacific/Galapagos",
// "Europe/Tallinn",
// "Africa/Cairo",
// "Africa/El_Aaiun",
// "Europe/Madrid",
// "Africa/Ceuta",
// "Atlantic/Canary",
// "Europe/Helsinki",
// "Pacific/Fiji",
// "Atlantic/Stanley",
// "Pacific/Chuuk",
// "Pacific/Pohnpei",
// "Pacific/Kosrae",
// "Atlantic/Faroe",
// "Europe/Paris",
// "Europe/London",
// "Asia/Tbilisi",
// "America/Cayenne",
// "Africa/Accra",
// "Europe/Gibraltar",
// "America/Godthab",
// "America/Danmarkshavn",
// "America/Scoresbysund",
// "America/Thule",
// "Europe/Athens",
// "Atlantic/South_Georgia",
// "America/Guatemala",
// "Pacific/Guam",
// "Africa/Bissau",
// "America/Guyana",
// "Asia/Hong_Kong",
// "America/Tegucigalpa",
// "America/Port-au-Prince",
// "Europe/Budapest",
// "Asia/Jakarta",
// "Asia/Pontianak",
// "Asia/Makassar",
// "Asia/Jayapura",
// "Europe/Dublin",
// "Asia/Jerusalem",
// "Asia/Kolkata",
// "Indian/Chagos",
// "Asia/Baghdad",
// "Asia/Tehran",
// "Atlantic/Reykjavik",
// "Europe/Rome",
// "America/Jamaica",
// "Asia/Amman",
// "Asia/Tokyo",
// "Africa/Nairobi",
// "Asia/Bishkek",
// "Pacific/Tarawa",
// "Pacific/Enderbury",
// "Pacific/Kiritimati",
// "Asia/Pyongyang",
// "Asia/Seoul",
// "Asia/Almaty",
// "Asia/Qyzylorda",
// "Asia/Qostanay",
// "Asia/Aqtobe",
// "Asia/Aqtau",
// "Asia/Atyrau",
// "Asia/Oral",
// "Asia/Beirut",
// "Asia/Colombo",
// "Africa/Monrovia",
// "Europe/Vilnius",
// "Europe/Luxembourg",
// "Europe/Riga",
// "Africa/Tripoli",
// "Africa/Casablanca",
// "Europe/Monaco",
// "Europe/Chisinau",
// "Pacific/Majuro",
// "Pacific/Kwajalein",
// "Asia/Yangon",
// "Asia/Ulaanbaatar",
// "Asia/Hovd",
// "Asia/Choibalsan",
// "Asia/Macau",
// "America/Martinique",
// "Europe/Malta",
// "Indian/Mauritius",
// "Indian/Maldives",
// "America/Mexico_City",
// "America/Cancun",
// "America/Merida",
// "America/Monterrey",
// "America/Matamoros",
// "America/Mazatlan",
// "America/Chihuahua",
// "America/Ojinaga",
// "America/Hermosillo",
// "America/Tijuana",
// "America/Bahia_Banderas",
// "Asia/Kuala_Lumpur",
// "Asia/Kuching",
// "Africa/Maputo",
// "Africa/Windhoek",
// "Pacific/Noumea",
// "Pacific/Norfolk",
// "Africa/Lagos",
// "America/Managua",
// "Europe/Amsterdam",
// "Europe/Oslo",
// "Asia/Kathmandu",
// "Pacific/Nauru",
// "Pacific/Niue",
// "Pacific/Auckland",
// "Pacific/Chatham",
// "America/Panama",
// "America/Lima",
// "Pacific/Tahiti",
// "Pacific/Marquesas",
// "Pacific/Gambier",
// "Pacific/Port_Moresby",
// "Pacific/Bougainville",
// "Asia/Manila",
// "Asia/Karachi",
// "Europe/Warsaw",
// "America/Miquelon",
// "Pacific/Pitcairn",
// "America/Puerto_Rico",
// "Asia/Gaza",
// "Asia/Hebron",
// "Europe/Lisbon",
// "Atlantic/Madeira",
// "Atlantic/Azores",
// "Pacific/Palau",
// "America/Asuncion",
// "Asia/Qatar",
// "Indian/Reunion",
// "Europe/Bucharest",
// "Europe/Belgrade",
// "Europe/Kaliningrad",
// "Europe/Moscow",
// "Europe/Simferopol",
// "Europe/Kirov",
// "Europe/Astrakhan",
// "Europe/Volgograd",
// "Europe/Saratov",
// "Europe/Ulyanovsk",
// "Europe/Samara",
// "Asia/Yekaterinburg",
// "Asia/Omsk",
// "Asia/Novosibirsk",
// "Asia/Barnaul",
// "Asia/Tomsk",
// "Asia/Novokuznetsk",
// "Asia/Krasnoyarsk",
// "Asia/Irkutsk",
// "Asia/Chita",
// "Asia/Yakutsk",
// "Asia/Khandyga",
// "Asia/Vladivostok",
// "Asia/Ust-Nera",
// "Asia/Magadan",
// "Asia/Sakhalin",
// "Asia/Srednekolymsk",
// "Asia/Kamchatka",
// "Asia/Anadyr",
// "Asia/Riyadh",
// "Pacific/Guadalcanal",
// "Indian/Mahe",
// "Africa/Khartoum",
// "Europe/Stockholm",
// "Asia/Singapore",
// "America/Paramaribo",
// "Africa/Juba",
// "Africa/Sao_Tome",
// "America/El_Salvador",
// "Asia/Damascus",
// "America/Grand_Turk",
// "Africa/Ndjamena",
// "Indian/Kerguelen",
// "Asia/Bangkok",
// "Asia/Dushanbe",
// "Pacific/Fakaofo",
// "Asia/Dili",
// "Asia/Ashgabat",
// "Africa/Tunis",
// "Pacific/Tongatapu",
// "Europe/Istanbul",
// "America/Port_of_Spain",
// "Pacific/Funafuti",
// "Asia/Taipei",
// "Europe/Kiev",
// "Europe/Uzhgorod",
// "Europe/Zaporozhye",
// "Pacific/Wake",
// "America/New_York",
// "America/Detroit",
// "America/Kentucky/Louisville",
// "America/Kentucky/Monticello",
// "America/Indiana/Indianapolis",
// "America/Indiana/Vincennes",
// "America/Indiana/Winamac",
// "America/Indiana/Marengo",
// "America/Indiana/Petersburg",
// "America/Indiana/Vevay",
// "America/Chicago",
// "America/Indiana/Tell_City",
// "America/Indiana/Knox",
// "America/Menominee",
// "America/North_Dakota/Center",
// "America/North_Dakota/New_Salem",
// "America/North_Dakota/Beulah",
// "America/Denver",
// "America/Boise",
// "America/Phoenix",
// "America/Los_Angeles",
// "America/Anchorage",
// "America/Juneau",
// "America/Sitka",
// "America/Metlakatla",
// "America/Yakutat",
// "America/Nome",
// "America/Adak",
// "Pacific/Honolulu",
// "America/Montevideo",
// "Asia/Samarkand",
// "Asia/Tashkent",
// "America/Caracas",
// "Asia/Ho_Chi_Minh",
// "Pacific/Efate",
// "Pacific/Wallis",
// "Pacific/Apia",
// "Africa/Johannesburg",
// ];

// export const timezoneListArray = [
//   "Europe/Andorra",
//   "Asia/Dubai",
//   "Asia/Kabul",
//   "Europe/Tirane",
//   "Asia/Yerevan",
//   "Antarctica/Casey",
//   "Antarctica/Davis",
//   "Antarctica/DumontDUrville",
//   "Antarctica/Mawson",
//   "Antarctica/Palmer",
//   "Antarctica/Rothera",
//   "Antarctica/Syowa",
//   "Antarctica/Troll",
//   "Antarctica/Vostok",
//   "America/Argentina/Buenos_Aires",
//   "America/Argentina/Cordoba",
//   "America/Argentina/Salta",
//   "America/Argentina/Jujuy",
//   "America/Argentina/Tucuman",
//   "America/Argentina/Catamarca",
//   "America/Argentina/La_Rioja",
//   "America/Argentina/San_Juan",
//   "America/Argentina/Mendoza",
//   "America/Argentina/San_Luis",
//   "America/Argentina/Rio_Gallegos",
//   "America/Argentina/Ushuaia",
//   "Pacific/Pago_Pago",
//   "Europe/Vienna",
//   "Australia/Lord_Howe",
//   "Antarctica/Macquarie",
//   "Australia/Hobart",
//   "Australia/Currie",
//   "Australia/Melbourne",
//   "Australia/Sydney",
//   "Australia/Broken_Hill",
//   "Australia/Brisbane",
//   "Australia/Lindeman",
//   "Australia/Adelaide",
//   "Australia/Darwin",
//   "Australia/Perth",
//   "Australia/Eucla",
//   "Asia/Baku",
//   "America/Barbados",
//   "Asia/Dhaka",
//   "Europe/Brussels",
//   "Europe/Sofia",
//   "Atlantic/Bermuda",
//   "Asia/Brunei",
//   "America/La_Paz",
//   "America/Noronha",
//   "America/Belem",
//   "America/Fortaleza",
//   "America/Recife",
//   "America/Araguaina",
//   "America/Maceio",
//   "America/Bahia",
//   "America/Sao_Paulo",
//   "America/Campo_Grande",
//   "America/Cuiaba",
//   "America/Santarem",
//   "America/Porto_Velho",
//   "America/Boa_Vista",
//   "America/Manaus",
//   "America/Eirunepe",
//   "America/Rio_Branco",
//   "America/Nassau",
//   "Asia/Thimphu",
//   "Europe/Minsk",
//   "America/Belize",
//   "America/St_Johns",
//   "America/Halifax",
//   "America/Glace_Bay",
//   "America/Moncton",
//   "America/Goose_Bay",
//   "America/Blanc-Sablon",
//   "America/Toronto",
//   "America/Nipigon",
//   "America/Thunder_Bay",
//   "America/Iqaluit",
//   "America/Pangnirtung",
//   "America/Atikokan",
//   "America/Winnipeg",
//   "America/Rainy_River",
//   "America/Resolute",
//   "America/Rankin_Inlet",
//   "America/Regina",
//   "America/Swift_Current",
//   "America/Edmonton",
//   "America/Cambridge_Bay",
//   "America/Yellowknife",
//   "America/Inuvik",
//   "America/Creston",
//   "America/Dawson_Creek",
//   "America/Fort_Nelson",
//   "America/Vancouver",
//   "America/Whitehorse",
//   "America/Dawson",
//   "Indian/Cocos",
//   "Europe/Zurich",
//   "Africa/Abidjan",
//   "Pacific/Rarotonga",
//   "America/Santiago",
//   "America/Punta_Arenas",
//   "Pacific/Easter",
//   "Asia/Shanghai",
//   "Asia/Urumqi",
//   "America/Bogota",
//   "America/Costa_Rica",
//   "America/Havana",
//   "Atlantic/Cape_Verde",
//   "America/Curacao",
//   "Indian/Christmas",
//   "Asia/Nicosia",
//   "Asia/Famagusta",
//   "Europe/Prague",
//   "Europe/Berlin",
//   "Europe/Copenhagen",
//   "America/Santo_Domingo",
//   "Africa/Algiers",
//   "America/Guayaquil",
//   "Pacific/Galapagos",
//   "Europe/Tallinn",
//   "Africa/Cairo",
//   "Africa/El_Aaiun",
//   "Europe/Madrid",
//   "Africa/Ceuta",
//   "Atlantic/Canary",
//   "Europe/Helsinki",
//   "Pacific/Fiji",
//   "Atlantic/Stanley",
//   "Pacific/Chuuk",
//   "Pacific/Pohnpei",
//   "Pacific/Kosrae",
//   "Atlantic/Faroe",
//   "Europe/Paris",
//   "Europe/London",
//   "Asia/Tbilisi",
//   "America/Cayenne",
//   "Africa/Accra",
//   "Europe/Gibraltar",
//   "America/Godthab",
//   "America/Danmarkshavn",
//   "America/Scoresbysund",
//   "America/Thule",
//   "Europe/Athens",
//   "Atlantic/South_Georgia",
//   "America/Guatemala",
//   "Pacific/Guam",
//   "Africa/Bissau",
//   "America/Guyana",
//   "Asia/Hong_Kong",
//   "America/Tegucigalpa",
//   "America/Port-au-Prince",
//   "Europe/Budapest",
//   "Asia/Jakarta",
//   "Asia/Pontianak",
//   "Asia/Makassar",
//   "Asia/Jayapura",
//   "Europe/Dublin",
//   "Asia/Jerusalem",
//   "Asia/Kolkata",
//   "Indian/Chagos",
//   "Asia/Baghdad",
//   "Asia/Tehran",
//   "Atlantic/Reykjavik",
//   "Europe/Rome",
//   "America/Jamaica",
//   "Asia/Amman",
//   "Asia/Tokyo",
//   "Africa/Nairobi",
//   "Asia/Bishkek",
//   "Pacific/Tarawa",
//   "Pacific/Enderbury",
//   "Pacific/Kiritimati",
//   "Asia/Pyongyang",
//   "Asia/Seoul",
//   "Asia/Almaty",
//   "Asia/Qyzylorda",
//   "Asia/Qostanay",
//   "Asia/Aqtobe",
//   "Asia/Aqtau",
//   "Asia/Atyrau",
//   "Asia/Oral",
//   "Asia/Beirut",
//   "Asia/Colombo",
//   "Africa/Monrovia",
//   "Europe/Vilnius",
//   "Europe/Luxembourg",
//   "Europe/Riga",
//   "Africa/Tripoli",
//   "Africa/Casablanca",
//   "Europe/Monaco",
//   "Europe/Chisinau",
//   "Pacific/Majuro",
//   "Pacific/Kwajalein",
//   "Asia/Yangon",
//   "Asia/Ulaanbaatar",
//   "Asia/Hovd",
//   "Asia/Choibalsan",
//   "Asia/Macau",
//   "America/Martinique",
//   "Europe/Malta",
//   "Indian/Mauritius",
//   "Indian/Maldives",
//   "America/Mexico_City",
//   "America/Cancun",
//   "America/Merida",
//   "America/Monterrey",
//   "America/Matamoros",
//   "America/Mazatlan",
//   "America/Ojinaga",
//   "America/Hermosillo",
//   "America/Tijuana",
//   "America/Bahia_Banderas",
//   "Asia/Kuala_Lumpur",
//   "Asia/Kuching",
//   "Africa/Maputo",
//   "Africa/Windhoek",
//   "Pacific/Noumea",
//   "Pacific/Norfolk",
//   "Africa/Lagos",
//   "America/Managua",
//   "Europe/Amsterdam",
//   "Europe/Oslo",
//   "Asia/Kathmandu",
//   "Pacific/Nauru",
//   "Pacific/Niue",
//   "Pacific/Auckland",
//   "Pacific/Chatham",
//   "America/Panama",
//   "America/Lima",
//   "Pacific/Tahiti",
//   "Pacific/Marquesas",
//   "Pacific/Gambier",
//   "Pacific/Port_Moresby",
//   "Pacific/Bougainville",
//   "Asia/Manila",
//   "Asia/Karachi",
//   "Europe/Warsaw",
//   "America/Miquelon",
//   "Pacific/Pitcairn",
//   "America/Puerto_Rico",
//   "Asia/Gaza",
//   "Asia/Hebron",
//   "Europe/Lisbon",
//   "Atlantic/Madeira",
//   "Atlantic/Azores",
//   "Pacific/Palau",
//   "America/Asuncion",
//   "Asia/Qatar",
//   "Indian/Reunion",
//   "Europe/Bucharest",
//   "Europe/Belgrade",
//   "Europe/Kaliningrad",
//   "Europe/Moscow",
//   "Europe/Simferopol",
//   "Europe/Kirov",
//   "Europe/Astrakhan",
//   "Europe/Volgograd",
//   "Europe/Saratov",
//   "Europe/Ulyanovsk",
//   "Europe/Samara",
//   "Asia/Yekaterinburg",
//   "Asia/Omsk",
//   "Asia/Novosibirsk",
//   "Asia/Barnaul",
//   "Asia/Tomsk",
//   "Asia/Novokuznetsk",
//   "Asia/Krasnoyarsk",
//   "Asia/Irkutsk",
//   "Asia/Chita",
//   "Asia/Yakutsk",
//   "Asia/Khandyga",
//   "Asia/Vladivostok",

//   "Asia/Ust-Nera",
//   "Asia/Magadan",
//   "Asia/Sakhalin",
//   "Asia/Srednekolymsk",
//   "Asia/Kamchatka",
//   "Asia/Anadyr",
//   "Asia/Riyadh",
//   "Pacific/Guadalcanal",
//   "Indian/Mahe",
//   "Africa/Khartoum",
//   "Europe/Stockholm",
//   "Asia/Singapore",
//   "America/Paramaribo",
//   "Africa/Juba",
//   "Africa/Sao_Tome",
//   "America/El_Salvador",
//   "Asia/Damascus",
//   "America/Grand_Turk",
//   "Africa/Ndjamena",
//   "Indian/Kerguelen",
//   "Asia/Bangkok",
//   "Asia/Dushanbe",
//   "Pacific/Fakaofo",
//   "Asia/Dili",
//   "Asia/Ashgabat",
//   "Africa/Tunis",
//   "Pacific/Tongatapu",
//   "Europe/Istanbul",
//   "America/Port_of_Spain",
//   "Pacific/Funafuti",
//   "Asia/Taipei",
//   "Europe/Kiev",
//   "Europe/Uzhgorod",
//   "Europe/Zaporozhye",
//   "Pacific/Wake",
//   "America/New_York",
//   "America/Detroit",
//   "America/Kentucky/Louisville",
//   "America/Kentucky/Monticello",
//   "America/Indiana/Indianapolis",
//   "America/Indiana/Vincennes",
//   "America/Indiana/Winamac",
//   "America/Indiana/Marengo",
//   "America/Indiana/Petersburg",
//   "America/Indiana/Vevay",
//   "America/Chicago",
//   "America/Indiana/Tell_City",
//   "America/Indiana/Knox",
//   "America/Menominee",
//   "America/North_Dakota/Center",
//   "America/North_Dakota/New_Salem",
//   "America/North_Dakota/Beulah",
//   "America/Denver",
//   "America/Boise",
//   "America/Phoenix",
//   "America/Los_Angeles",
//   "America/Anchorage",
//   "America/Juneau",
//   "America/Sitka",
//   "America/Metlakatla",
//   "America/Yakutat",
//   "America/Nome",
//   "America/Adak",
//   "Pacific/Honolulu",
//   "America/Montevideo",
//   "Asia/Samarkand",
//   "Asia/Tashkent",
//   "America/Caracas",
//   "Asia/Ho_Chi_Minh",
//   "Pacific/Efate",
//   "Pacific/Wallis",
//   "Pacific/Apia",
//   "Africa/Johannesburg",
//   "America/Chihuahua",
//   "Asia/Saigon",
//   "Asia/Saigon",
//   "Africa/Addis_Ababa",
//   "Africa/Asmara",
//   "Africa/Asmera",
//   "Africa/Bamako",
//   "Africa/Bangui",
//   "Africa/Banjul",
//   "Africa/Blantyre",
//   "Africa/Brazzaville",
//   "Africa/Bujumbura",
//   "Africa/Conakry",
//   "Africa/Dakar",
//   "Africa/Dar_es_Salaam",
//   "Africa/Djibouti",
//   "Africa/Douala",
//   "Africa/Freetown",
//   "Africa/Gaborone",
//   "Africa/Harare",
//   "Africa/Kampala",
//   "Africa/Kigali",
//   "Africa/Kinshasa",
//   "Africa/Libreville",
//   "Africa/Lome",
//   "Africa/Luanda",
//   "Africa/Lubumbashi",
//   "Africa/Lusaka",
//   "Africa/Malabo",
//   "Africa/Maseru",
//   "Africa/Mbabane",
//   "Africa/Mogadishu",
//   "Africa/Niamey",
//   "Africa/Nouakchott",
//   "Africa/Ouagadougou",
//   "Africa/Porto-Novo",
//   "Africa/Timbuktu",
//   "America/Anguilla",
//   "America/Antigua",
//   "America/Argentina/ComodRivadavia",
//   "America/Aruba",
//   "America/Atka",
//   "America/Buenos_Aires",
//   "America/Catamarca",
//   "America/Cayman",
//   "America/Coral_Harbour",
//   "America/Cordoba",
//   "America/Dominica",
//   "America/Ensenada",
//   "America/Fort_Wayne",
//   "America/Grenada",
//   "America/Guadeloupe",
//   "America/Indianapolis",
//   "America/Jujuy",
//   "America/Knox_IN",
//   "America/Kralendijk",
//   "America/Louisville",
//   "America/Lower_Princes",
//   "America/Marigot",
//   "America/Mendoza",
//   "America/Montreal",
//   "America/Montserrat",
//   "America/Porto_Acre",
//   "America/Rosario",
//   "America/Santa_Isabel",
//   "America/Shiprock",
//   "America/St_Barthelemy",
//   "America/St_Kitts",
//   "America/St_Lucia",
//   "America/St_Thomas",
//   "America/St_Vincent",
//   "America/Tortola",
//   "America/Virgin",
//   "Antarctica/McMurdo",
//   "Arctic/Longyearbyen",
//   "Antarctica/South_Pole",
//   "Asia/Aden",
//   "Asia/Ashkhabad",
//   "Asia/Bahrain",
//   "Asia/Calcutta",
//   "Asia/Chongqing",
//   "Asia/Chungking",
//   "Asia/Dacca",
//   "Asia/Harbin",
//   "Asia/Istanbul",
//   "Asia/Kashgar",
//   "Asia/Katmandu",
//   "Asia/Kuwait",
//   "Asia/Macao",
//   "Asia/Muscat",
//   "Asia/Phnom_Penh",
//   "Asia/Rangoon",
//   "Asia/Saigon",
//   "Asia/Tel_Aviv",
//   "Asia/Thimbu",
//   "Asia/Ujung_Pandang",
//   "Asia/Ulan_Bator",
//   "Asia/Vientiane",
//   "Atlantic/Faeroe",
//   "Atlantic/Jan_Mayen",
//   "Atlantic/St_Helena",
//   "Australia/ACT",
//   "Australia/Canberra",
//   "Australia/LHI",
//   "Australia/NSW",
//   "Australia/North",
//   "Australia/Queensland",
//   "Australia/South",
//   "Australia/Tasmania",
//   "Australia/Victoria",
//   "Australia/West",
//   "Australia/Yancowinna",
//   "Brazil/Acre",
//   "Brazil/DeNoronha",
//   "Brazil/East",
//   "Brazil/West",
//   "CET",
//   "CST6CDT",
//   "Canada/Atlantic",
//   "Canada/Central",
//   "Canada/Eastern",
//   "Canada/Mountain",
//   "Canada/Newfoundland",
//   "Canada/Pacific",
//   "Canada/Saskatchewan",
//   "Canada/Yukon",
//   "Chile/Continental",
//   "Chile/EasterIsland",
//   "Cuba",
//   "EET",
//   "EST",
//   "EST5EDT",
//   "Egypt",
//   "Eire",
//   "Etc/GMT",
//   "Etc/GMT+0",
//   "Etc/GMT+1",
//   "Etc/GMT+10",
//   "Etc/GMT+11",

//   "Etc/GMT+12",
//   "Etc/GMT+2",
//   "Etc/GMT+3",
//   "Etc/GMT+4",
//   "Etc/GMT+5",
//   "Etc/GMT+6",
//   "Etc/GMT+7",
//   "Etc/GMT+8",
//   "Etc/GMT+9",
//   "Etc/GMT-0",
//   "Etc/GMT-1",
//   "Etc/GMT-10",
//   "Etc/GMT-11",
//   "Etc/GMT-12",
//   "Etc/GMT-13",
//   "Etc/GMT-14",
//   "Etc/GMT-2",
//   "Etc/GMT-3",
//   "Etc/GMT-4",
//   "Etc/GMT-5",
//   "Etc/GMT-6",
//   "Etc/GMT-7",
//   "Etc/GMT-8",
//   "Etc/GMT-9",
//   "Etc/GMT0",
//   "Etc/Greenwich",
//   "Etc/UCT",
//   "Etc/UTC",
//   "Etc/Universal",
//   "Etc/Zulu",
//   "Europe/Belfast",
//   "Europe/Bratislava",
//   "Europe/Busingen",
//   "Europe/Guernsey",
//   "Europe/Isle_of_Man",
//   "Europe/Jersey",
//   "Europe/Ljubljana",
//   "Europe/Mariehamn",
//   "Europe/Nicosia",
//   "Europe/Podgorica",
//   "Europe/San_Marino",
//   "Europe/Sarajevo",
//   "Europe/Skopje",
//   "Europe/Tiraspol",
//   "Europe/Vaduz",
//   "Europe/Vatican",
//   "Europe/Zagreb",
//   "GB",
//   "GB-Eire",
//   "GMT",
//   "GMT+0",
//   "GMT-0",
//   "GMT0",
//   "Greenwich",
//   "HST",
//   "Hongkong",
//   "Iceland",
//   "Indian/Antananarivo",
//   "Indian/Comoro",
//   "Indian/Mayotte",
//   "Iran",
//   "Israel",
//   "Jamaica",
//   "Japan",
//   "Kwajalein",
//   "Libya",
//   "MET",
//   "MST",
//   "MST7MDT",
//   "Mexico/BajaNorte",
//   "Mexico/BajaSur",
//   "Mexico/General",
//   "NZ",
//   "NZ-CHAT",
//   "Navajo",

//   "PRC",

//   "PST8PDT",

//   "Pacific/Johnston",

//   "Pacific/Midway",

//   "Pacific/Ponape",

//   "Pacific/Saipan",

//   "Pacific/Samoa",

//   "Pacific/Truk",

//   "Pacific/Yap",

//   "Poland",

//   "Portugal",

//   "ROC",

//   "ROK",

//   "Singapore",

//   "Turkey",

//   "UCT",

//   "US/Alaska",

//   "US/Aleutian",

//   "US/Arizona",

//   "US/Central",

//   "US/East-Indiana",

//   "US/Eastern",

//   "US/Hawaii",

//   "US/Indiana-Starke",

//   "US/Michigan",

//   "US/Mountain",

//   "US/Pacific",

//   "US/Pacific-New",

//   "US/Samoa",

//   "UTC",

//   "Universal",

//   "W-SU",

//   "WET",

//   "Zulu",
// ];

export var timezoneList = [{
  value: "Europe/Andorra",
  label: "Europe/Andorra"
}, {
  value: "Asia/Dubai",
  label: "Asia/Dubai"
}, {
  value: "Asia/Kabul",
  label: "Asia/Kabul"
}, {
  value: "Europe/Tirane",
  label: "Europe/Tirane"
}, {
  value: "Asia/Yerevan",
  label: "Asia/Yerevan"
}, {
  value: "Antarctica/Casey",
  label: "Antarctica/Casey"
}, {
  value: "Antarctica/Davis",
  label: "Antarctica/Davis"
}, {
  value: "Antarctica/DumontDUrville",
  label: "Antarctica/DumontDUrville"
}, {
  value: "Antarctica/Mawson",
  label: "Antarctica/Mawson"
}, {
  value: "Antarctica/Palmer",
  label: "Antarctica/Palmer"
}, {
  value: "Antarctica/Rothera",
  label: "Antarctica/Rothera"
}, {
  value: "Antarctica/Syowa",
  label: "Antarctica/Syowa"
}, {
  value: "Antarctica/Troll",
  label: "Antarctica/Troll"
}, {
  value: "Antarctica/Vostok",
  label: "Antarctica/Vostok"
}, {
  value: "America/Argentina/Buenos_Aires",
  label: "America/Argentina/Buenos_Aires"
}, {
  value: "America/Argentina/Cordoba",
  label: "America/Argentina/Cordoba"
}, {
  value: "America/Argentina/Salta",
  label: "America/Argentina/Salta"
}, {
  value: "America/Argentina/Jujuy",
  label: "America/Argentina/Jujuy"
}, {
  value: "America/Argentina/Tucuman",
  label: "America/Argentina/Tucuman"
}, {
  value: "America/Argentina/Catamarca",
  label: "America/Argentina/Catamarca"
}, {
  value: "America/Argentina/La_Rioja",
  label: "America/Argentina/La_Rioja"
}, {
  value: "America/Argentina/San_Juan",
  label: "America/Argentina/San_Juan"
}, {
  value: "America/Argentina/Mendoza",
  label: "America/Argentina/Mendoza"
}, {
  value: "America/Argentina/San_Luis",
  label: "America/Argentina/San_Luis"
}, {
  value: "America/Argentina/Rio_Gallegos",
  label: "America/Argentina/Rio_Gallegos"
}, {
  value: "America/Argentina/Ushuaia",
  label: "America/Argentina/Ushuaia"
}, {
  value: "Pacific/Pago_Pago",
  label: "Pacific/Pago_Pago"
}, {
  value: "Europe/Vienna",
  label: "Europe/Vienna"
}, {
  value: "Australia/Lord_Howe",
  label: "Australia/Lord_Howe"
}, {
  value: "Antarctica/Macquarie",
  label: "Antarctica/Macquarie"
}, {
  value: "Australia/Hobart",
  label: "Australia/Hobart"
}, {
  value: "Australia/Currie",
  label: "Australia/Currie"
}, {
  value: "Australia/Melbourne",
  label: "Australia/Melbourne"
}, {
  value: "Australia/Sydney",
  label: "Australia/Sydney"
}, {
  value: "Australia/Broken_Hill",
  label: "Australia/Broken_Hill"
}, {
  value: "Australia/Brisbane",
  label: "Australia/Brisbane"
}, {
  value: "Australia/Lindeman",
  label: "Australia/Lindeman"
}, {
  value: "Australia/Adelaide",
  label: "Australia/Adelaide"
}, {
  value: "Australia/Darwin",
  label: "Australia/Darwin"
}, {
  value: "Australia/Perth",
  label: "Australia/Perth"
}, {
  value: "Australia/Eucla",
  label: "Australia/Eucla"
}, {
  value: "Asia/Baku",
  label: "Asia/Baku"
}, {
  value: "America/Barbados",
  label: "America/Barbados"
}, {
  value: "Asia/Dhaka",
  label: "Asia/Dhaka"
}, {
  value: "Europe/Brussels",
  label: "Europe/Brussels"
}, {
  value: "Europe/Sofia",
  label: "Europe/Sofia"
}, {
  value: "Atlantic/Bermuda",
  label: "Atlantic/Bermuda"
}, {
  value: "Asia/Brunei",
  label: "Asia/Brunei"
}, {
  value: "America/La_Paz",
  label: "America/La_Paz"
}, {
  value: "America/Noronha",
  label: "America/Noronha"
}, {
  value: "America/Belem",
  label: "America/Belem"
}, {
  value: "America/Fortaleza",
  label: "America/Fortaleza"
}, {
  value: "America/Recife",
  label: "America/Recife"
}, {
  value: "America/Araguaina",
  label: "America/Araguaina"
}, {
  value: "America/Maceio",
  label: "America/Maceio"
}, {
  value: "America/Bahia",
  label: "America/Bahia"
}, {
  value: "America/Sao_Paulo",
  label: "America/Sao_Paulo"
}, {
  value: "America/Campo_Grande",
  label: "America/Campo_Grande"
}, {
  value: "America/Cuiaba",
  label: "America/Cuiaba"
}, {
  value: "America/Santarem",
  label: "America/Santarem"
}, {
  value: "America/Porto_Velho",
  label: "America/Porto_Velho"
}, {
  value: "America/Boa_Vista",
  label: "America/Boa_Vista"
}, {
  value: "America/Manaus",
  label: "America/Manaus"
}, {
  value: "America/Eirunepe",
  label: "America/Eirunepe"
}, {
  value: "America/Rio_Branco",
  label: "America/Rio_Branco"
}, {
  value: "America/Nassau",
  label: "America/Nassau"
}, {
  value: "Asia/Thimphu",
  label: "Asia/Thimphu"
}, {
  value: "Europe/Minsk",
  label: "Europe/Minsk"
}, {
  value: "America/Belize",
  label: "America/Belize"
}, {
  value: "America/St_Johns",
  label: "America/St_Johns"
}, {
  value: "America/Halifax",
  label: "America/Halifax"
}, {
  value: "America/Glace_Bay",
  label: "America/Glace_Bay"
}, {
  value: "America/Moncton",
  label: "America/Moncton"
}, {
  value: "America/Goose_Bay",
  label: "America/Goose_Bay"
}, {
  value: "America/Blanc-Sablon",
  label: "America/Blanc-Sablon"
}, {
  value: "America/Toronto",
  label: "America/Toronto"
}, {
  value: "America/Nipigon",
  label: "America/Nipigon"
}, {
  value: "America/Thunder_Bay",
  label: "America/Thunder_Bay"
}, {
  value: "America/Iqaluit",
  label: "America/Iqaluit"
}, {
  value: "America/Pangnirtung",
  label: "America/Pangnirtung"
}, {
  value: "America/Atikokan",
  label: "America/Atikokan"
}, {
  value: "America/Winnipeg",
  label: "America/Winnipeg"
}, {
  value: "America/Rainy_River",
  label: "America/Rainy_River"
}, {
  value: "America/Resolute",
  label: "America/Resolute"
}, {
  value: "America/Rankin_Inlet",
  label: "America/Rankin_Inlet"
}, {
  value: "America/Regina",
  label: "America/Regina"
}, {
  value: "America/Swift_Current",
  label: "America/Swift_Current"
}, {
  value: "America/Edmonton",
  label: "America/Edmonton"
}, {
  value: "America/Cambridge_Bay",
  label: "America/Cambridge_Bay"
}, {
  value: "America/Yellowknife",
  label: "America/Yellowknife"
}, {
  value: "America/Inuvik",
  label: "America/Inuvik"
}, {
  value: "America/Creston",
  label: "America/Creston"
}, {
  value: "America/Dawson_Creek",
  label: "America/Dawson_Creek"
}, {
  value: "America/Fort_Nelson",
  label: "America/Fort_Nelson"
}, {
  value: "America/Vancouver",
  label: "America/Vancouver"
}, {
  value: "America/Whitehorse",
  label: "America/Whitehorse"
}, {
  value: "America/Dawson",
  label: "America/Dawson"
}, {
  value: "Indian/Cocos",
  label: "Indian/Cocos"
}, {
  value: "Europe/Zurich",
  label: "Europe/Zurich"
}, {
  value: "Africa/Abidjan",
  label: "Africa/Abidjan"
}, {
  value: "Pacific/Rarotonga",
  label: "Pacific/Rarotonga"
}, {
  value: "America/Santiago",
  label: "America/Santiago"
}, {
  value: "America/Punta_Arenas",
  label: "America/Punta_Arenas"
}, {
  value: "Pacific/Easter",
  label: "Pacific/Easter"
}, {
  value: "Asia/Shanghai",
  label: "Asia/Shanghai"
}, {
  value: "Asia/Urumqi",
  label: "Asia/Urumqi"
}, {
  value: "America/Bogota",
  label: "America/Bogota"
}, {
  value: "America/Costa_Rica",
  label: "America/Costa_Rica"
}, {
  value: "America/Havana",
  label: "America/Havana"
}, {
  value: "Atlantic/Cape_Verde",
  label: "Atlantic/Cape_Verde"
}, {
  value: "America/Curacao",
  label: "America/Curacao"
}, {
  value: "Indian/Christmas",
  label: "Indian/Christmas"
}, {
  value: "Asia/Nicosia",
  label: "Asia/Nicosia"
}, {
  value: "Asia/Famagusta",
  label: "Asia/Famagusta"
}, {
  value: "Europe/Prague",
  label: "Europe/Prague"
}, {
  value: "Europe/Berlin",
  label: "Europe/Berlin"
}, {
  value: "Europe/Copenhagen",
  label: "Europe/Copenhagen"
}, {
  value: "America/Santo_Domingo",
  label: "America/Santo_Domingo"
}, {
  value: "Africa/Algiers",
  label: "Africa/Algiers"
}, {
  value: "America/Guayaquil",
  label: "America/Guayaquil"
}, {
  value: "Pacific/Galapagos",
  label: "Pacific/Galapagos"
}, {
  value: "Europe/Tallinn",
  label: "Europe/Tallinn"
}, {
  value: "Africa/Cairo",
  label: "Africa/Cairo"
}, {
  value: "Africa/El_Aaiun",
  label: "Africa/El_Aaiun"
}, {
  value: "Europe/Madrid",
  label: "Europe/Madrid"
}, {
  value: "Africa/Ceuta",
  label: "Africa/Ceuta"
}, {
  value: "Atlantic/Canary",
  label: "Atlantic/Canary"
}, {
  value: "Europe/Helsinki",
  label: "Europe/Helsinki"
}, {
  value: "Pacific/Fiji",
  label: "Pacific/Fiji"
}, {
  value: "Atlantic/Stanley",
  label: "Atlantic/Stanley"
}, {
  value: "Pacific/Chuuk",
  label: "Pacific/Chuuk"
}, {
  value: "Pacific/Pohnpei",
  label: "Pacific/Pohnpei"
}, {
  value: "Pacific/Kosrae",
  label: "Pacific/Kosrae"
}, {
  value: "Atlantic/Faroe",
  label: "Atlantic/Faroe"
}, {
  value: "Europe/Paris",
  label: "Europe/Paris"
}, {
  value: "Europe/London",
  label: "Europe/London"
}, {
  value: "Asia/Tbilisi",
  label: "Asia/Tbilisi"
}, {
  value: "America/Cayenne",
  label: "America/Cayenne"
}, {
  value: "Africa/Accra",
  label: "Africa/Accra"
}, {
  value: "Europe/Gibraltar",
  label: "Europe/Gibraltar"
}, {
  value: "America/Godthab",
  label: "America/Godthab"
}, {
  value: "America/Danmarkshavn",
  label: "America/Danmarkshavn"
}, {
  value: "America/Scoresbysund",
  label: "America/Scoresbysund"
}, {
  value: "America/Thule",
  label: "America/Thule"
}, {
  value: "Europe/Athens",
  label: "Europe/Athens"
}, {
  value: "Atlantic/South_Georgia",
  label: "Atlantic/South_Georgia"
}, {
  value: "America/Guatemala",
  label: "America/Guatemala"
}, {
  value: "Pacific/Guam",
  label: "Pacific/Guam"
}, {
  value: "Africa/Bissau",
  label: "Africa/Bissau"
}, {
  value: "America/Guyana",
  label: "America/Guyana"
}, {
  value: "Asia/Hong_Kong",
  label: "Asia/Hong_Kong"
}, {
  value: "America/Tegucigalpa",
  label: "America/Tegucigalpa"
}, {
  value: "America/Port-au-Prince",
  label: "America/Port-au-Prince"
}, {
  value: "Europe/Budapest",
  label: "Europe/Budapest"
}, {
  value: "Asia/Jakarta",
  label: "Asia/Jakarta"
}, {
  value: "Asia/Pontianak",
  label: "Asia/Pontianak"
}, {
  value: "Asia/Makassar",
  label: "Asia/Makassar"
}, {
  value: "Asia/Jayapura",
  label: "Asia/Jayapura"
}, {
  value: "Europe/Dublin",
  label: "Europe/Dublin"
}, {
  value: "Asia/Jerusalem",
  label: "Asia/Jerusalem"
}, {
  value: "Asia/Kolkata",
  label: "Asia/Kolkata"
}, {
  value: "Indian/Chagos",
  label: "Indian/Chagos"
}, {
  value: "Asia/Baghdad",
  label: "Asia/Baghdad"
}, {
  value: "Asia/Tehran",
  label: "Asia/Tehran"
}, {
  value: "Atlantic/Reykjavik",
  label: "Atlantic/Reykjavik"
}, {
  value: "Europe/Rome",
  label: "Europe/Rome"
}, {
  value: "America/Jamaica",
  label: "America/Jamaica"
}, {
  value: "Asia/Amman",
  label: "Asia/Amman"
}, {
  value: "Asia/Tokyo",
  label: "Asia/Tokyo"
}, {
  value: "Africa/Nairobi",
  label: "Africa/Nairobi"
}, {
  value: "Asia/Bishkek",
  label: "Asia/Bishkek"
}, {
  value: "Pacific/Tarawa",
  label: "Pacific/Tarawa"
}, {
  value: "Pacific/Enderbury",
  label: "Pacific/Enderbury"
}, {
  value: "Pacific/Kiritimati",
  label: "Pacific/Kiritimati"
}, {
  value: "Asia/Pyongyang",
  label: "Asia/Pyongyang"
}, {
  value: "Asia/Seoul",
  label: "Asia/Seoul"
}, {
  value: "Asia/Almaty",
  label: "Asia/Almaty"
}, {
  value: "Asia/Qyzylorda",
  label: "Asia/Qyzylorda"
}, {
  value: "Asia/Qostanay",
  label: "Asia/Qostanay"
}, {
  value: "Asia/Aqtobe",
  label: "Asia/Aqtobe"
}, {
  value: "Asia/Aqtau",
  label: "Asia/Aqtau"
}, {
  value: "Asia/Atyrau",
  label: "Asia/Atyrau"
}, {
  value: "Asia/Oral",
  label: "Asia/Oral"
}, {
  value: "Asia/Beirut",
  label: "Asia/Beirut"
}, {
  value: "Asia/Colombo",
  label: "Asia/Colombo"
}, {
  value: "Africa/Monrovia",
  label: "Africa/Monrovia"
}, {
  value: "Europe/Vilnius",
  label: "Europe/Vilnius"
}, {
  value: "Europe/Luxembourg",
  label: "Europe/Luxembourg"
}, {
  value: "Europe/Riga",
  label: "Europe/Riga"
}, {
  value: "Africa/Tripoli",
  label: "Africa/Tripoli"
}, {
  value: "Africa/Casablanca",
  label: "Africa/Casablanca"
}, {
  value: "Europe/Monaco",
  label: "Europe/Monaco"
}, {
  value: "Europe/Chisinau",
  label: "Europe/Chisinau"
}, {
  value: "Pacific/Majuro",
  label: "Pacific/Majuro"
}, {
  value: "Pacific/Kwajalein",
  label: "Pacific/Kwajalein"
}, {
  value: "Asia/Yangon",
  label: "Asia/Yangon"
}, {
  value: "Asia/Ulaanbaatar",
  label: "Asia/Ulaanbaatar"
}, {
  value: "Asia/Hovd",
  label: "Asia/Hovd"
}, {
  value: "Asia/Choibalsan",
  label: "Asia/Choibalsan"
}, {
  value: "Asia/Macau",
  label: "Asia/Macau"
}, {
  value: "America/Martinique",
  label: "America/Martinique"
}, {
  value: "Europe/Malta",
  label: "Europe/Malta"
}, {
  value: "Indian/Mauritius",
  label: "Indian/Mauritius"
}, {
  value: "Indian/Maldives",
  label: "Indian/Maldives"
}, {
  value: "America/Mexico_City",
  label: "America/Mexico_City"
}, {
  value: "America/Cancun",
  label: "America/Cancun"
}, {
  value: "America/Merida",
  label: "America/Merida"
}, {
  value: "America/Monterrey",
  label: "America/Monterrey"
}, {
  value: "America/Matamoros",
  label: "America/Matamoros"
}, {
  value: "America/Mazatlan",
  label: "America/Mazatlan"
}, {
  value: "America/Ojinaga",
  label: "America/Ojinaga"
}, {
  value: "America/Hermosillo",
  label: "America/Hermosillo"
}, {
  value: "America/Tijuana",
  label: "America/Tijuana"
}, {
  value: "America/Bahia_Banderas",
  label: "America/Bahia_Banderas"
}, {
  value: "Asia/Kuala_Lumpur",
  label: "Asia/Kuala_Lumpur"
}, {
  value: "Asia/Kuching",
  label: "Asia/Kuching"
}, {
  value: "Africa/Maputo",
  label: "Africa/Maputo"
}, {
  value: "Africa/Windhoek",
  label: "Africa/Windhoek"
}, {
  value: "Pacific/Noumea",
  label: "Pacific/Noumea"
}, {
  value: "Pacific/Norfolk",
  label: "Pacific/Norfolk"
}, {
  value: "Africa/Lagos",
  label: "Africa/Lagos"
}, {
  value: "America/Managua",
  label: "America/Managua"
}, {
  value: "Europe/Amsterdam",
  label: "Europe/Amsterdam"
}, {
  value: "Europe/Oslo",
  label: "Europe/Oslo"
}, {
  value: "Asia/Kathmandu",
  label: "Asia/Kathmandu"
}, {
  value: "Pacific/Nauru",
  label: "Pacific/Nauru"
}, {
  value: "Pacific/Niue",
  label: "Pacific/Niue"
}, {
  value: "Pacific/Auckland",
  label: "Pacific/Auckland"
}, {
  value: "Pacific/Chatham",
  label: "Pacific/Chatham"
}, {
  value: "America/Panama",
  label: "America/Panama"
}, {
  value: "America/Lima",
  label: "America/Lima"
}, {
  value: "Pacific/Tahiti",
  label: "Pacific/Tahiti"
}, {
  value: "Pacific/Marquesas",
  label: "Pacific/Marquesas"
}, {
  value: "Pacific/Gambier",
  label: "Pacific/Gambier"
}, {
  value: "Pacific/Port_Moresby",
  label: "Pacific/Port_Moresby"
}, {
  value: "Pacific/Bougainville",
  label: "Pacific/Bougainville"
}, {
  value: "Asia/Manila",
  label: "Asia/Manila"
}, {
  value: "Asia/Karachi",
  label: "Asia/Karachi"
}, {
  value: "Europe/Warsaw",
  label: "Europe/Warsaw"
}, {
  value: "America/Miquelon",
  label: "America/Miquelon"
}, {
  value: "Pacific/Pitcairn",
  label: "Pacific/Pitcairn"
}, {
  value: "America/Puerto_Rico",
  label: "America/Puerto_Rico"
}, {
  value: "Asia/Gaza",
  label: "Asia/Gaza"
}, {
  value: "Asia/Hebron",
  label: "Asia/Hebron"
}, {
  value: "Europe/Lisbon",
  label: "Europe/Lisbon"
}, {
  value: "Atlantic/Madeira",
  label: "Atlantic/Madeira"
}, {
  value: "Atlantic/Azores",
  label: "Atlantic/Azores"
}, {
  value: "Pacific/Palau",
  label: "Pacific/Palau"
}, {
  value: "America/Asuncion",
  label: "America/Asuncion"
}, {
  value: "Asia/Qatar",
  label: "Asia/Qatar"
}, {
  value: "Indian/Reunion",
  label: "Indian/Reunion"
}, {
  value: "Europe/Bucharest",
  label: "Europe/Bucharest"
}, {
  value: "Europe/Belgrade",
  label: "Europe/Belgrade"
}, {
  value: "Europe/Kaliningrad",
  label: "Europe/Kaliningrad"
}, {
  value: "Europe/Moscow",
  label: "Europe/Moscow"
}, {
  value: "Europe/Simferopol",
  label: "Europe/Simferopol"
}, {
  value: "Europe/Kirov",
  label: "Europe/Kirov"
}, {
  value: "Europe/Astrakhan",
  label: "Europe/Astrakhan"
}, {
  value: "Europe/Volgograd",
  label: "Europe/Volgograd"
}, {
  value: "Europe/Saratov",
  label: "Europe/Saratov"
}, {
  value: "Europe/Ulyanovsk",
  label: "Europe/Ulyanovsk"
}, {
  value: "Europe/Samara",
  label: "Europe/Samara"
}, {
  value: "Asia/Yekaterinburg",
  label: "Asia/Yekaterinburg"
}, {
  value: "Asia/Omsk",
  label: "Asia/Omsk"
}, {
  value: "Asia/Novosibirsk",
  label: "Asia/Novosibirsk"
}, {
  value: "Asia/Barnaul",
  label: "Asia/Barnaul"
}, {
  value: "Asia/Tomsk",
  label: "Asia/Tomsk"
}, {
  value: "Asia/Novokuznetsk",
  label: "Asia/Novokuznetsk"
}, {
  value: "Asia/Krasnoyarsk",
  label: "Asia/Krasnoyarsk"
}, {
  value: "Asia/Irkutsk",
  label: "Asia/Irkutsk"
}, {
  value: "Asia/Chita",
  label: "Asia/Chita"
}, {
  value: "Asia/Yakutsk",
  label: "Asia/Yakutsk"
}, {
  value: "Asia/Khandyga",
  label: "Asia/Khandyga"
}, {
  value: "Asia/Vladivostok",
  label: "Asia/Vladivostok"
}, {
  value: "Asia/Ust-Nera",
  label: "Asia/Ust-Nera"
}, {
  value: "Asia/Magadan",
  label: "Asia/Magadan"
}, {
  value: "Asia/Sakhalin",
  label: "Asia/Sakhalin"
}, {
  value: "Asia/Srednekolymsk",
  label: "Asia/Srednekolymsk"
}, {
  value: "Asia/Kamchatka",
  label: "Asia/Kamchatka"
}, {
  value: "Asia/Anadyr",
  label: "Asia/Anadyr"
}, {
  value: "Asia/Riyadh",
  label: "Asia/Riyadh"
}, {
  value: "Pacific/Guadalcanal",
  label: "Pacific/Guadalcanal"
}, {
  value: "Indian/Mahe",
  label: "Indian/Mahe"
}, {
  value: "Africa/Khartoum",
  label: "Africa/Khartoum"
}, {
  value: "Europe/Stockholm",
  label: "Europe/Stockholm"
}, {
  value: "Asia/Singapore",
  label: "Asia/Singapore"
}, {
  value: "America/Paramaribo",
  label: "America/Paramaribo"
}, {
  value: "Africa/Juba",
  label: "Africa/Juba"
}, {
  value: "Africa/Sao_Tome",
  label: "Africa/Sao_Tome"
}, {
  value: "America/El_Salvador",
  label: "America/El_Salvador"
}, {
  value: "Asia/Damascus",
  label: "Asia/Damascus"
}, {
  value: "America/Grand_Turk",
  label: "America/Grand_Turk"
}, {
  value: "Africa/Ndjamena",
  label: "Africa/Ndjamena"
}, {
  value: "Indian/Kerguelen",
  label: "Indian/Kerguelen"
}, {
  value: "Asia/Bangkok",
  label: "Asia/Bangkok"
}, {
  value: "Asia/Dushanbe",
  label: "Asia/Dushanbe"
}, {
  value: "Pacific/Fakaofo",
  label: "Pacific/Fakaofo"
}, {
  value: "Asia/Dili",
  label: "Asia/Dili"
}, {
  value: "Asia/Ashgabat",
  label: "Asia/Ashgabat"
}, {
  value: "Africa/Tunis",
  label: "Africa/Tunis"
}, {
  value: "Pacific/Tongatapu",
  label: "Pacific/Tongatapu"
}, {
  value: "Europe/Istanbul",
  label: "Europe/Istanbul"
}, {
  value: "America/Port_of_Spain",
  label: "America/Port_of_Spain"
}, {
  value: "Pacific/Funafuti",
  label: "Pacific/Funafuti"
}, {
  value: "Asia/Taipei",
  label: "Asia/Taipei"
}, {
  value: "Europe/Kiev",
  label: "Europe/Kiev"
}, {
  value: "Europe/Uzhgorod",
  label: "Europe/Uzhgorod"
}, {
  value: "Europe/Zaporozhye",
  label: "Europe/Zaporozhye"
}, {
  value: "Pacific/Wake",
  label: "Pacific/Wake"
}, {
  value: "America/New_York",
  label: "America/New_York"
}, {
  value: "America/Detroit",
  label: "America/Detroit"
}, {
  value: "America/Kentucky/Louisville",
  label: "America/Kentucky/Louisville"
}, {
  value: "America/Kentucky/Monticello",
  label: "America/Kentucky/Monticello"
}, {
  value: "America/Indiana/Indianapolis",
  label: "America/Indiana/Indianapolis"
}, {
  value: "America/Indiana/Vincennes",
  label: "America/Indiana/Vincennes"
}, {
  value: "America/Indiana/Winamac",
  label: "America/Indiana/Winamac"
}, {
  value: "America/Indiana/Marengo",
  label: "America/Indiana/Marengo"
}, {
  value: "America/Indiana/Petersburg",
  label: "America/Indiana/Petersburg"
}, {
  value: "America/Indiana/Vevay",
  label: "America/Indiana/Vevay"
}, {
  value: "America/Chicago",
  label: "America/Chicago"
}, {
  value: "America/Indiana/Tell_City",
  label: "America/Indiana/Tell_City"
}, {
  value: "America/Indiana/Knox",
  label: "America/Indiana/Knox"
}, {
  value: "America/Menominee",
  label: "America/Menominee"
}, {
  value: "America/North_Dakota/Center",
  label: "America/North_Dakota/Center"
}, {
  value: "America/North_Dakota/New_Salem",
  label: "America/North_Dakota/New_Salem"
}, {
  value: "America/North_Dakota/Beulah",
  label: "America/North_Dakota/Beulah"
}, {
  value: "America/Denver",
  label: "America/Denver"
}, {
  value: "America/Boise",
  label: "America/Boise"
}, {
  value: "America/Phoenix",
  label: "America/Phoenix"
}, {
  value: "America/Los_Angeles",
  label: "America/Los_Angeles"
}, {
  value: "America/Anchorage",
  label: "America/Anchorage"
}, {
  value: "America/Juneau",
  label: "America/Juneau"
}, {
  value: "America/Sitka",
  label: "America/Sitka"
}, {
  value: "America/Metlakatla",
  label: "America/Metlakatla"
}, {
  value: "America/Yakutat",
  label: "America/Yakutat"
}, {
  value: "America/Nome",
  label: "America/Nome"
}, {
  value: "America/Adak",
  label: "America/Adak"
}, {
  value: "Pacific/Honolulu",
  label: "Pacific/Honolulu"
}, {
  value: "America/Montevideo",
  label: "America/Montevideo"
}, {
  value: "Asia/Samarkand",
  label: "Asia/Samarkand"
}, {
  value: "Asia/Tashkent",
  label: "Asia/Tashkent"
}, {
  value: "America/Caracas",
  label: "America/Caracas"
}, {
  value: "Asia/Ho_Chi_Minh",
  label: "Asia/Ho_Chi_Minh"
}, {
  value: "Pacific/Efate",
  label: "Pacific/Efate"
}, {
  value: "Pacific/Wallis",
  label: "Pacific/Wallis"
}, {
  value: "Pacific/Apia",
  label: "Pacific/Apia"
}, {
  value: "Africa/Johannesburg",
  label: "Africa/Johannesburg"
}, {
  value: "America/Chihuahua",
  label: "America/Chihuahua"
}, {
  value: "Asia/Saigon",
  label: "Asia/Saigon"
}, {
  value: "Africa/Addis_Ababa",
  label: "Africa/Addis_Ababa"
}, {
  value: "Africa/Asmara",
  label: "Africa/Asmara"
}, {
  value: "Africa/Bamako",
  label: "Africa/Bamako"
}, {
  value: "Africa/Bangui",
  label: "Africa/Bangui"
}, {
  value: "Africa/Banjul",
  label: "Africa/Banjul"
}, {
  value: "Africa/Blantyre",
  label: "Africa/Blantyre"
}, {
  value: "Africa/Brazzaville",
  label: "Africa/Brazzaville"
}, {
  value: "Africa/Bujumbura",
  label: "Africa/Bujumbura"
}, {
  value: "Africa/Conakry",
  label: "Africa/Conakry"
}, {
  value: "Africa/Dakar",
  label: "Africa/Dakar"
}, {
  value: "Africa/Dar_es_Salaam",
  label: "Africa/Dar_es_Salaam"
}, {
  value: "Africa/Djibouti",
  label: "Africa/Djibouti"
}, {
  value: "Africa/Douala",
  label: "Africa/Douala"
}, {
  value: "Africa/Freetown",
  label: "Africa/Freetown"
}, {
  value: "Africa/Gaborone",
  label: "Africa/Gaborone"
}, {
  value: "Africa/Harare",
  label: "Africa/Harare"
}, {
  value: "Africa/Kampala",
  label: "Africa/Kampala"
}, {
  value: "Africa/Kigali",
  label: "Africa/Kigali"
}, {
  value: "Africa/Kinshasa",
  label: "Africa/Kinshasa"
}, {
  value: "Africa/Libreville",
  label: "Africa/Libreville"
}, {
  value: "Africa/Lome",
  label: "Africa/Lome"
}, {
  value: "Africa/Luanda",
  label: "Africa/Luanda"
}, {
  value: "Africa/Lubumbashi",
  label: "Africa/Lubumbashi"
}, {
  value: "Africa/Lusaka",
  label: "Africa/Lusaka"
}, {
  value: "Africa/Malabo",
  label: "Africa/Malabo"
}, {
  value: "Africa/Maseru",
  label: "Africa/Maseru"
}, {
  value: "Africa/Mbabane",
  label: "Africa/Mbabane"
}, {
  value: "Africa/Mogadishu",
  label: "Africa/Mogadishu"
}, {
  value: "Africa/Niamey",
  label: "Africa/Niamey"
}, {
  value: "Africa/Nouakchott",
  label: "Africa/Nouakchott"
}, {
  value: "Africa/Ouagadougou",
  label: "Africa/Ouagadougou"
}, {
  value: "Africa/Porto-Novo",
  label: "Africa/Porto-Novo"
}, {
  value: "Africa/Timbuktu",
  label: "Africa/Timbuktu"
}, {
  value: "America/Anguilla",
  label: "America/Anguilla"
}, {
  value: "America/Antigua",
  label: "America/Antigua"
}, {
  value: "America/Argentina/ComodRivadavia",
  label: "America/Argentina/ComodRivadavia"
}, {
  value: "America/Aruba",
  label: "America/Aruba"
}, {
  value: "America/Atka",
  label: "America/Atka"
}, {
  value: "America/Buenos_Aires",
  label: "America/Buenos_Aires"
}, {
  value: "America/Catamarca",
  label: "America/Catamarca"
}, {
  value: "America/Cayman",
  label: "America/Cayman"
}, {
  value: "America/Coral_Harbour",
  label: "America/Coral_Harbour"
}, {
  value: "America/Cordoba",
  label: "America/Cordoba"
}, {
  value: "America/Dominica",
  label: "America/Dominica"
}, {
  value: "America/Ensenada",
  label: "America/Ensenada"
}, {
  value: "America/Fort_Wayne",
  label: "America/Fort_Wayne"
}, {
  value: "America/Grenada",
  label: "America/Grenada"
}, {
  value: "America/Guadeloupe",
  label: "America/Guadeloupe"
}, {
  value: "America/Indianapolis",
  label: "America/Indianapolis"
}, {
  value: "America/Jujuy",
  label: "America/Jujuy"
}, {
  value: "America/Knox_IN",
  label: "America/Knox_IN"
}, {
  value: "America/Kralendijk",
  label: "America/Kralendijk"
}, {
  value: "America/Louisville",
  label: "America/Louisville"
}, {
  value: "America/Lower_Princes",
  label: "America/Lower_Princes"
}, {
  value: "America/Marigot",
  label: "America/Marigot"
}, {
  value: "America/Mendoza",
  label: "America/Mendoza"
}, {
  value: "America/Montreal",
  label: "America/Montreal"
}, {
  value: "America/Montserrat",
  label: "America/Montserrat"
}, {
  value: "America/Porto_Acre",
  label: "America/Porto_Acre"
}, {
  value: "America/Rosario",
  label: "America/Rosario"
}, {
  value: "America/Santa_Isabel",
  label: "America/Santa_Isabel"
}, {
  value: "America/Shiprock",
  label: "America/Shiprock"
}, {
  value: "America/St_Barthelemy",
  label: "America/St_Barthelemy"
}, {
  value: "America/St_Kitts",
  label: "America/St_Kitts"
}, {
  value: "America/St_Lucia",
  label: "America/St_Lucia"
}, {
  value: "America/St_Thomas",
  label: "America/St_Thomas"
}, {
  value: "America/St_Vincent",
  label: "America/St_Vincent"
}, {
  value: "America/Tortola",
  label: "America/Tortola"
}, {
  value: "America/Virgin",
  label: "America/Virgin"
}, {
  value: "Antarctica/McMurdo",
  label: "Antarctica/McMurdo"
}, {
  value: "Arctic/Longyearbyen",
  label: "Arctic/Longyearbyen"
}, {
  value: "Antarctica/South_Pole",
  label: "Antarctica/South_Pole"
}, {
  value: "Asia/Aden",
  label: "Asia/Aden"
}, {
  value: "Asia/Ashkhabad",
  label: "Asia/Ashkhabad"
}, {
  value: "Asia/Bahrain",
  label: "Asia/Bahrain"
}, {
  value: "Asia/Calcutta",
  label: "Asia/Calcutta"
}, {
  value: "Asia/Chongqing",
  label: "Asia/Chongqing"
}, {
  value: "Asia/Chungking",
  label: "Asia/Chungking"
}, {
  value: "Asia/Dacca",
  label: "Asia/Dacca"
}, {
  value: "Asia/Harbin",
  label: "Asia/Harbin"
}, {
  value: "Asia/Istanbul",
  label: "Asia/Istanbul"
}, {
  value: "Asia/Kashgar",
  label: "Asia/Kashgar"
}, {
  value: "Asia/Katmandu",
  label: "Asia/Katmandu"
}, {
  value: "Asia/Kuwait",
  label: "Asia/Kuwait"
}, {
  value: "Asia/Macao",
  label: "Asia/Macao"
}, {
  value: "Asia/Muscat",
  label: "Asia/Muscat"
}, {
  value: "Asia/Phnom_Penh",
  label: "Asia/Phnom_Penh"
}, {
  value: "Asia/Rangoon",
  label: "Asia/Rangoon"
}, {
  value: "Asia/Tel_Aviv",
  label: "Asia/Tel_Aviv"
}, {
  value: "Asia/Thimbu",
  label: "Asia/Thimbu"
}, {
  value: "Asia/Ujung_Pandang",
  label: "Asia/Ujung_Pandang"
}, {
  value: "Asia/Ulan_Bator",
  label: "Asia/Ulan_Bator"
}, {
  value: "Asia/Vientiane",
  label: "Asia/Vientiane"
}, {
  value: "Atlantic/Faeroe",
  label: "Atlantic/Faeroe"
}, {
  value: "Atlantic/Jan_Mayen",
  label: "Atlantic/Jan_Mayen"
}, {
  value: "Atlantic/St_Helena",
  label: "Atlantic/St_Helena"
}, {
  value: "Australia/ACT",
  label: "Australia/ACT"
}, {
  value: "Australia/Canberra",
  label: "Australia/Canberra"
}, {
  value: "Australia/LHI",
  label: "Australia/LHI"
}, {
  value: "Australia/NSW",
  label: "Australia/NSW"
}, {
  value: "Australia/North",
  label: "Australia/North"
}, {
  value: "Australia/Queensland",
  label: "Australia/Queensland"
}, {
  value: "Australia/South",
  label: "Australia/South"
}, {
  value: "Australia/Tasmania",
  label: "Australia/Tasmania"
}, {
  value: "Australia/Victoria",
  label: "Australia/Victoria"
}, {
  value: "Australia/West",
  label: "Australia/West"
}, {
  value: "Australia/Yancowinna",
  label: "Australia/Yancowinna"
}, {
  value: "Brazil/Acre",
  label: "Brazil/Acre"
}, {
  value: "Brazil/DeNoronha",
  label: "Brazil/DeNoronha"
}, {
  value: "Brazil/East",
  label: "Brazil/East"
}, {
  value: "Brazil/West",
  label: "Brazil/West"
}, {
  value: "CET",
  label: "CET"
}, {
  value: "CST6CDT",
  label: "CST6CDT"
}, {
  value: "Canada/Atlantic",
  label: "Canada/Atlantic"
}, {
  value: "Canada/Central",
  label: "Canada/Central"
}, {
  value: "Canada/Eastern",
  label: "Canada/Eastern"
}, {
  value: "Canada/Mountain",
  label: "Canada/Mountain"
}, {
  value: "Canada/Newfoundland",
  label: "Canada/Newfoundland"
}, {
  value: "Canada/Pacific",
  label: "Canada/Pacific"
}, {
  value: "Canada/Saskatchewan",
  label: "Canada/Saskatchewan"
}, {
  value: "Canada/Yukon",
  label: "Canada/Yukon"
}, {
  value: "Chile/Continental",
  label: "Chile/Continental"
}, {
  value: "Chile/EasterIsland",
  label: "Chile/EasterIsland"
}, {
  value: "Cuba",
  label: "Cuba"
}, {
  value: "EET",
  label: "EET"
}, {
  value: "EST",
  label: "EST"
}, {
  value: "EST5EDT",
  label: "EST5EDT"
}, {
  value: "Egypt",
  label: "Egypt"
}, {
  value: "Eire",
  label: "Eire"
}, {
  value: "Etc/GMT",
  label: "Etc/GMT"
}, {
  value: "Etc/GMT+0",
  label: "Etc/GMT+0"
}, {
  value: "Etc/GMT+1",
  label: "Etc/GMT+1"
}, {
  value: "Etc/GMT+10",
  label: "Etc/GMT+10"
}, {
  value: "Etc/GMT+11",
  label: "Etc/GMT+11"
}, {
  value: "Etc/GMT+12",
  label: "Etc/GMT+12"
}, {
  value: "Etc/GMT+2",
  label: "Etc/GMT+2"
}, {
  value: "Etc/GMT+3",
  label: "Etc/GMT+3"
}, {
  value: "Etc/GMT+4",
  label: "Etc/GMT+4"
}, {
  value: "Etc/GMT+5",
  label: "Etc/GMT+5"
}, {
  value: "Etc/GMT+6",
  label: "Etc/GMT+6"
}, {
  value: "Etc/GMT+7",
  label: "Etc/GMT+7"
}, {
  value: "Etc/GMT+8",
  label: "Etc/GMT+8"
}, {
  value: "Etc/GMT+9",
  label: "Etc/GMT+9"
}, {
  value: "Etc/GMT-0",
  label: "Etc/GMT-0"
}, {
  value: "Etc/GMT-1",
  label: "Etc/GMT-1"
}, {
  value: "Etc/GMT-10",
  label: "Etc/GMT-10"
}, {
  value: "Etc/GMT-11",
  label: "Etc/GMT-11"
}, {
  value: "Etc/GMT-12",
  label: "Etc/GMT-12"
}, {
  value: "Etc/GMT-13",
  label: "Etc/GMT-13"
}, {
  value: "Etc/GMT-14",
  label: "Etc/GMT-14"
}, {
  value: "Etc/GMT-2",
  label: "Etc/GMT-2"
}, {
  value: "Etc/GMT-3",
  label: "Etc/GMT-3"
}, {
  value: "Etc/GMT-4",
  label: "Etc/GMT-4"
}, {
  value: "Etc/GMT-5",
  label: "Etc/GMT-5"
}, {
  value: "Etc/GMT-6",
  label: "Etc/GMT-6"
}, {
  value: "Etc/GMT-7",
  label: "Etc/GMT-7"
}, {
  value: "Etc/GMT-8",
  label: "Etc/GMT-8"
}, {
  value: "Etc/GMT-9",
  label: "Etc/GMT-9"
}, {
  value: "Etc/GMT0",
  label: "Etc/GMT0"
}, {
  value: "Etc/Greenwich",
  label: "Etc/Greenwich"
}, {
  value: "Etc/UCT",
  label: "Etc/UCT"
}, {
  value: "Etc/UTC",
  label: "Etc/UTC"
}, {
  value: "Etc/Universal",
  label: "Etc/Universal"
}, {
  value: "Etc/Zulu",
  label: "Etc/Zulu"
}, {
  value: "Europe/Belfast",
  label: "Europe/Belfast"
}, {
  value: "Europe/Bratislava",
  label: "Europe/Bratislava"
}, {
  value: "Europe/Busingen",
  label: "Europe/Busingen"
}, {
  value: "Europe/Guernsey",
  label: "Europe/Guernsey"
}, {
  value: "Europe/Isle_of_Man",
  label: "Europe/Isle_of_Man"
}, {
  value: "Europe/Jersey",
  label: "Europe/Jersey"
}, {
  value: "Europe/Ljubljana",
  label: "Europe/Ljubljana"
}, {
  value: "Europe/Mariehamn",
  label: "Europe/Mariehamn"
}, {
  value: "Europe/Nicosia",
  label: "Europe/Nicosia"
}, {
  value: "Europe/Podgorica",
  label: "Europe/Podgorica"
}, {
  value: "Europe/San_Marino",
  label: "Europe/San_Marino"
}, {
  value: "Europe/Sarajevo",
  label: "Europe/Sarajevo"
}, {
  value: "Europe/Skopje",
  label: "Europe/Skopje"
}, {
  value: "Europe/Tiraspol",
  label: "Europe/Tiraspol"
}, {
  value: "Europe/Vaduz",
  label: "Europe/Vaduz"
}, {
  value: "Europe/Vatican",
  label: "Europe/Vatican"
}, {
  value: "Europe/Zagreb",
  label: "Europe/Zagreb"
}, {
  value: "GB",
  label: "GB"
}, {
  value: "GB-Eire",
  label: "GB-Eire"
}, {
  value: "GMT",
  label: "GMT"
}, {
  value: "GMT+0",
  label: "GMT+0"
}, {
  value: "GMT-0",
  label: "GMT-0"
}, {
  value: "GMT0",
  label: "GMT0"
}, {
  value: "Greenwich",
  label: "Greenwich"
}, {
  value: "HST",
  label: "HST"
}, {
  value: "Hongkong",
  label: "Hongkong"
}, {
  value: "Iceland",
  label: "Iceland"
}, {
  value: "Indian/Antananarivo",
  label: "Indian/Antananarivo"
}, {
  value: "Indian/Comoro",
  label: "Indian/Comoro"
}, {
  value: "Indian/Mayotte",
  label: "Indian/Mayotte"
}, {
  value: "Iran",
  label: "Iran"
}, {
  value: "Israel",
  label: "Israel"
}, {
  value: "Jamaica",
  label: "Jamaica"
}, {
  value: "Japan",
  label: "Japan"
}, {
  value: "Kwajalein",
  label: "Kwajalein"
}, {
  value: "Libya",
  label: "Libya"
}, {
  value: "MET",
  label: "MET"
}, {
  value: "MST",
  label: "MST"
}, {
  value: "MST7MDT",
  label: "MST7MDT"
}, {
  value: "Mexico/BajaNorte",
  label: "Mexico/BajaNorte"
}, {
  value: "Mexico/BajaSur",
  label: "Mexico/BajaSur"
}, {
  value: "Mexico/General",
  label: "Mexico/General"
}, {
  value: "NZ",
  label: "NZ"
}, {
  value: "NZ-CHAT",
  label: "NZ-CHAT"
}, {
  value: "Navajo",
  label: "Navajo"
}, {
  value: "PRC",
  label: "PRC"
}, {
  value: "PST8PDT",
  label: "PST8PDT"
}, {
  value: "Pacific/Johnston",
  label: "Pacific/Johnston"
}, {
  value: "Pacific/Midway",
  label: "Pacific/Midway"
}, {
  value: "Pacific/Ponape",
  label: "Pacific/Ponape"
}, {
  value: "Pacific/Saipan",
  label: "Pacific/Saipan"
}, {
  value: "Pacific/Samoa",
  label: "Pacific/Samoa"
}, {
  value: "Pacific/Truk",
  label: "Pacific/Truk"
}, {
  value: "Pacific/Yap",
  label: "Pacific/Yap"
}, {
  value: "Poland",
  label: "Poland"
}, {
  value: "Portugal",
  label: "Portugal"
}, {
  value: "ROC",
  label: "ROC"
}, {
  value: "ROK",
  label: "ROK"
}, {
  value: "Singapore",
  label: "Singapore"
}, {
  value: "Turkey",
  label: "Turkey"
}, {
  value: "UCT",
  label: "UCT"
}, {
  value: "US/Alaska",
  label: "US/Alaska"
}, {
  value: "US/Aleutian",
  label: "US/Aleutian"
}, {
  value: "US/Arizona",
  label: "US/Arizona"
}, {
  value: "US/Central",
  label: "US/Central"
}, {
  value: "US/East-Indiana",
  label: "US/East-Indiana"
}, {
  value: "US/Eastern",
  label: "US/Eastern"
}, {
  value: "US/Hawaii",
  label: "US/Hawaii"
}, {
  value: "US/Indiana-Starke",
  label: "US/Indiana-Starke"
}, {
  value: "US/Michigan",
  label: "US/Michigan"
}, {
  value: "US/Mountain",
  label: "US/Mountain"
}, {
  value: "US/Pacific",
  label: "US/Pacific"
}, {
  value: "US/Pacific-New",
  label: "US/Pacific-New"
}, {
  value: "US/Samoa",
  label: "US/Samoa"
}, {
  value: "UTC",
  label: "UTC"
}, {
  value: "Universal",
  label: "Universal"
}, {
  value: "W-SU",
  label: "W-SU"
}, {
  value: "WET",
  label: "WET"
}, {
  value: "Zulu",
  label: "Zulu"
}];
export var IdelTimeThresholdList = [{
  mins: "1 min",
  minsvalue: "1"
}, {
  mins: "2 mins",
  minsvalue: "2"
}, {
  mins: "3 mins",
  minsvalue: "3"
}, {
  mins: "4 mins",
  minsvalue: "4"
}, {
  mins: "5 mins",
  minsvalue: "5"
}, {
  mins: "6 mins",
  minsvalue: "6"
}, {
  mins: "7 mins",
  minsvalue: "7"
}, {
  mins: "8 mins",
  minsvalue: "8"
}, {
  mins: "9 mins",
  minsvalue: "9"
}, {
  mins: "10 mins",
  minsvalue: "10"
}, {
  mins: "11 mins",
  minsvalue: "11"
}, {
  mins: "12 mins",
  minsvalue: "12"
}, {
  mins: "13 mins",
  minsvalue: "13"
}, {
  mins: "14 mins",
  minsvalue: "14"
}, {
  mins: "15 mins",
  minsvalue: "15"
}, {
  mins: "16 mins",
  minsvalue: "16"
}, {
  mins: "17 mins",
  minsvalue: "17"
}, {
  mins: "18 mins",
  minsvalue: "18"
}, {
  mins: "19 mins",
  minsvalue: "19"
}, {
  mins: "20 mins",
  minsvalue: "20"
}, {
  mins: "21 mins",
  minsvalue: "21"
}, {
  mins: "22 mins",
  minsvalue: "22"
}, {
  mins: "23 mins",
  minsvalue: "23"
}, {
  mins: "24 mins",
  minsvalue: "24"
}, {
  mins: "25 mins",
  minsvalue: "25"
}, {
  mins: "26 mins",
  minsvalue: "26"
}, {
  mins: "27 mins",
  minsvalue: "27"
}, {
  mins: "28 mins",
  minsvalue: "28"
}, {
  mins: "29 mins",
  minsvalue: "29"
}, {
  mins: "30 mins",
  minsvalue: "30"
}, {
  mins: "60 mins",
  secsvalue: "3600"
}, {
  mins: "90 mins",
  secsvalue: "5400"
}, {
  mins: "120 mins",
  secsvalue: "7200"
}];
/*  idleMinutesThreshold (seconds) list */
export var IdelTimeThresholdListSec = [{
  mins: "1 min",
  secsvalue: "60"
}, {
  mins: "2 mins",
  secsvalue: "120"
}, {
  mins: "3 mins",
  secsvalue: "180"
}, {
  mins: "4 mins",
  secsvalue: "240"
}, {
  mins: "5 mins",
  secsvalue: "300"
}, {
  mins: "6 mins",
  secsvalue: "360"
}, {
  mins: "7 mins",
  secsvalue: "420"
}, {
  mins: "8 mins",
  secsvalue: "480"
}, {
  mins: "9 mins",
  secsvalue: "540"
}, {
  mins: "10 mins",
  secsvalue: "600"
}, {
  mins: "11 mins",
  secsvalue: "660"
}, {
  mins: "12 mins",
  secsvalue: "720"
}, {
  mins: "13 mins",
  secsvalue: "780"
}, {
  mins: "14 mins",
  secsvalue: "840"
}, {
  mins: "15 mins",
  secsvalue: "900"
}, {
  mins: "16 mins",
  secsvalue: "960"
}, {
  mins: "17 mins",
  secsvalue: "1020"
}, {
  mins: "18 mins",
  secsvalue: "1080"
}, {
  mins: "19 mins",
  secsvalue: "1140"
}, {
  mins: "20 mins",
  secsvalue: "1200"
}, {
  mins: "21 mins",
  secsvalue: "1260"
}, {
  mins: "22 mins",
  secsvalue: "1320"
}, {
  mins: "23 mins",
  secsvalue: "1380"
}, {
  mins: "24 mins",
  secsvalue: "1440"
}, {
  mins: "25 mins",
  secsvalue: "1500"
}, {
  mins: "26 mins",
  secsvalue: "1560"
}, {
  mins: "27 mins",
  secsvalue: "1620"
}, {
  mins: "28 mins",
  secsvalue: "1680"
}, {
  mins: "28 mins",
  secsvalue: "1680"
}, {
  mins: "28 mins",
  secsvalue: "1680"
}, {
  mins: "29 mins",
  secsvalue: "1740"
}, {
  mins: "30 mins",
  secsvalue: "1800"
}, {
  mins: "60 mins",
  secsvalue: "3600"
}, {
  mins: "90 mins",
  secsvalue: "5400"
}, {
  mins: "120 mins",
  secsvalue: "7200"
}];