var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8;
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import { gql } from "@apollo/client";
export var GET_ASANA_OAUTH_TOKEN_QUERY = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  query getAsanaOAuthToken {\n    getAsanaOAuthToken {\n      url\n    }\n  }\n"])));
export var ADD_ACCESS_TOKEN_MUTATION = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  mutation addAccessToken($organisationId: String!, $code: String!) {\n    addAccessToken(organisationId: $organisationId, code: $code)\n  }\n"])));
export var GET_ZOHO_ACCESS_TOKEN_MUTATION = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  mutation getZohoAccessToken($organisationId: String!, $code: String!) {\n    getZohoAccessToken(organisationId: $organisationId, code: $code)\n  }\n"])));
export var GET_INTEGRATION_DETAILS_QUERY = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  query getIntegrationDetails($organisationId: String!) {\n    getIntegrationDetails(organisationId: $organisationId) {\n      apiName\n      icon\n      isIntegrated\n    }\n  }\n"])));
export var IMPORT_INTEGRATION_PROJECT_MUTATION = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  mutation importIntegrationProject($organisationId: String!, $apiName: String!) {\n    importIntegrationProject(organisationId: $organisationId, apiName: $apiName)\n  }\n"])));
export var IMPORT_INTEGRATION_PROJECT_AND_TASK_MUTATION = gql(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  mutation importIntegrationProjectAndTask($organisationId: String!, $apiName: String!) {\n    importIntegrationProjectAndTask(organisationId: $organisationId, apiName: $apiName)\n  }\n"])));
export var GET_OAUTH_TOKEN_FOR_BASECAMP_MUTATION = gql(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  mutation getOAuthTokenForBasecamp($organisationId: String!, $code: String!) {\n    getOAuthTokenForBasecamp(organisationId: $organisationId, code: $code)\n  }\n"])));
export var DISCONNECT_INTEGRATION_DETAILS_MUTATION = gql(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  mutation disconnectIntegrationDetails($organisationId: String!, $apiName: String!) {\n    disconnectIntegrationDetails(organisationId: $organisationId, apiName: $apiName)\n  }\n"])));