var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10;
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import { gql } from "@apollo/client";
export var MY_PROJECTS_QUERY = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  query myProjects(\n    $organisationId: String!\n    $url: String!\n    # $startDate: Float!\n    # $endDate: Float!\n    $timeZone: String!\n    $limit: Int!\n    $offset: Int!\n    $filter: String\n    $role: String!\n  ) {\n    myProjects(\n      organisationId: $organisationId\n      url: $url\n      # startDate: $startDate\n      # endDate: $endDate\n      timeZone: $timeZone\n      limit: $limit\n      offset: $offset\n      filter: $filter\n      role: $role\n    ) {\n      projectsData {\n        projectId\n        avgProgressPercentage\n        displayName\n        teamIds\n        estimatedTimeInHours\n        estimatedTime\n        projectTotalWorkedTime\n        projectSelectedHeadList\n        currentWorkStatus\n        recentProjectUser {\n          user {\n            userId\n            displayName\n            profileUrl\n          }\n          projectId\n          taskDate\n        }\n        recentProjectDateText\n      }\n      totalProjectCount\n    }\n  }\n"])));
export var ADD_NEW_PROJECT_MUTATION = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  mutation addNewProject(\n    $projectName: String!\n    $projectTime: Int\n    $organisationId: String!\n    $headIds: [String]!\n    $projectUsers: [ProjectUsers]!\n  ) {\n    addNewProject(\n      projectName: $projectName\n      projectTime: $projectTime\n      organisationId: $organisationId\n      headIds: $headIds\n      projectUsers: $projectUsers\n    ) {\n      projectName\n      projectId\n      estimatedTime\n    }\n  }\n"])));
export var EDIT_PROJECT_AND_DELETE_PROJECT_MUTATION = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  mutation editProjectAndDeleteProject(\n    $projectName: String\n    $projectId: String!\n    # $teamId: [String]\n    $status: String\n    $organisationId: String!\n    $projectTime: String\n    # $headIds: [String]!\n    $projectUsers: [String]!\n    $budget: Int\n  ) {\n    editProjectAndDeleteProject(\n      projectName: $projectName\n      projectId: $projectId\n      # teamId: $teamId\n      status: $status\n      organisationId: $organisationId\n      projectTime: $projectTime\n      # headIds: $headIds\n      projectUsers: $projectUsers\n      budget: $budget\n    )\n  }\n"])));
export var ORGANISATION_TEAMS_QUERY = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  query OrganisationTeams($organisationId: String!) {\n    organisationTeams(organisationId: $organisationId) {\n      teamName\n      teamId\n    }\n  }\n"])));
export var PROJECT_USER_DETAILS_DATA_QUERY = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  query getProjectUserDetails($organisationId: String!, $projectId: String) {\n    getProjectUserDetails(organisationId: $organisationId, projectId: $projectId) {\n      projectHeadList {\n        label\n        value\n      }\n      teamUserList {\n        displayName\n        userId\n        profileUrl\n        roleId\n      }\n\n      teamSelectedUserList {\n        userId\n      }\n    }\n  }\n"])));
export var MY_PROJECT_HEADER_QUERY = gql(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  query myProjectHeader($organisationId: String!, $timeZone: String!, $url: String!, $role: String!) {\n    myProjectHeader(organisationId: $organisationId, timeZone: $timeZone, url: $url, role: $role) {\n      projectStatusCount {\n        label\n        value\n        count\n      }\n    }\n  }\n"])));
export var MY_PROJECT_SUMMARY_QUERY = gql(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  query myProjectSummary($organisationId: String!, $timeZone: String!, $startDate: Float!, $endDate: Float!) {\n    myProjectSummary(organisationId: $organisationId, timeZone: $timeZone, startDate: $startDate, endDate: $endDate) {\n      projectSummary {\n        projectsEnabledTo\n        workingProjectMembersUserCount\n        workingProjectMembersUser {\n          userId\n          displayName\n          profileUrl\n        }\n        todayProjectTime\n        taskCount\n      }\n    }\n  }\n"])));
export var ADD_NEW_PROJECT_DETAILS_MUTATION = gql(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  mutation addNewProjectDetails($projectName: String!, $organisationId: String!, $projectUsers: [String]) {\n    addNewProjectDetails(projectName: $projectName, organisationId: $organisationId, projectUsers: $projectUsers) {\n      projectName\n      projectId\n      estimatedTime\n    }\n  }\n"])));
export var ADD_PROJECT_USER_DETAILS_MUTATION = gql(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  mutation addProjectUserDetails($projectId: String!, $organisationId: String!, $projectUsers: [String]!) {\n    addProjectUserDetails(projectId: $projectId, organisationId: $organisationId, projectUsers: $projectUsers) {\n      projectId\n    }\n  }\n"])));
export var UPDATE_PROJECT_ESTIMATED_TIME_MUTATION = gql(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  mutation updateProjectEstimatedTime($projectId: String!, $organisationId: String!, $projectTime: Int!) {\n    updateProjectEstimatedTime(projectId: $projectId, organisationId: $organisationId, projectTime: $projectTime)\n  }\n"])));