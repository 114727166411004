var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10;
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import { gql } from "@apollo/client";
export var GET_LEAVE_HISTORY_QUERY = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  query getleaveHistory(\n    $organisationId: String!\n    $userId: String\n    $timeZone: String!\n    $limit: Int!\n    $offset: Int\n    $leaveYearStartTime: String\n    $leaveYearEndTime: String\n    $teamId: String!\n    $userRole: String!\n  ) {\n    getleaveHistory(\n      organisationId: $organisationId\n      userId: $userId\n      timeZone: $timeZone\n      limit: $limit\n      offset: $offset\n      leaveYearStartTime: $leaveYearStartTime\n      leaveYearEndTime: $leaveYearEndTime\n\n      teamId: $teamId\n      userRole: $userRole\n    ) {\n      userId\n      appliedLeave\n      approvedLeave\n      pendingLeave\n      rejectedLeave\n      user {\n        userId\n        displayName\n        uniqueUrl\n        profileUrl\n      }\n    }\n  }\n"])));
export var USER_LEAVE_HISTORY_QUERY = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  query userLeaveHistory(\n    $organisationId: String!\n    $userId: String!\n    $timeZone: String!\n    $limit: Int!\n    $offset: Int\n    $leaveYearStartTime: String\n    $leaveYearEndTime: String\n  ) {\n    userLeaveHistory(\n      organisationId: $organisationId\n      userId: $userId\n      timeZone: $timeZone\n      limit: $limit\n      offset: $offset\n      leaveYearStartTime: $leaveYearStartTime\n      leaveYearEndTime: $leaveYearEndTime\n    ) {\n      leaveId\n      leaveType\n      leaveStartDate\n      leaveEndDate\n      leaveDays\n      appliedStatus\n      leaveReason\n      appliedAt\n\n      leaveStartDateFormat\n      leaveEndDateFormat\n\n      dateDetails {\n        date\n        dayType\n        dateFormat\n        dayFormat\n        nonWorkingDayLeaveSession\n        dateFormatEmail\n        dateFormatTooltip\n      }\n    }\n  }\n"])));
export var USER_LEAVE_SUMMARY_EXPORT_QUERY = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  query userLeaveSummaryExport(\n    $organisationId: String!\n    $userId: String\n    $timeZone: String!\n    $leaveYearStartTime: String\n    $leaveYearEndTime: String\n    $teamId: String!\n    $userRole: String!\n  ) {\n    userLeaveSummaryExport(\n      organisationId: $organisationId\n      userId: $userId\n      timeZone: $timeZone\n      leaveYearStartTime: $leaveYearStartTime\n      leaveYearEndTime: $leaveYearEndTime\n\n      teamId: $teamId\n      userRole: $userRole\n    ) {\n      userId\n      appliedLeave\n      approvedLeave\n      pendingLeave\n      rejectedLeave\n      user {\n        userId\n        displayName\n        uniqueUrl\n        profileUrl\n      }\n    }\n  }\n"])));
export var LEAVE_BALANCE_QUERY = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  query getLeaveSummaryBalance(\n    $organisationId: String!\n    $teamId: String!\n    $startTime: Float!\n    $endTime: Float!\n    $userId: String\n    $timeZone: String!\n    $limit: Int!\n    $offset: Int!\n    $userRole: String!\n  ) {\n    getLeaveSummaryBalance(\n      organisationId: $organisationId\n      teamId: $teamId\n      startTime: $startTime\n      endTime: $endTime\n      userId: $userId\n      timeZone: $timeZone\n      limit: $limit\n      offset: $offset\n      userRole: $userRole\n    ) {\n      userData {\n        employee\n        profileUrl\n        uniqueUrl\n        userId\n        date {\n          dateValue\n          value\n          data {\n            available\n            booked\n            carryForwardCount\n            leaveCount\n          }\n        }\n        totalLeave\n      }\n\n      headerData {\n        Header\n        type\n        leaveCycle\n        columns {\n          Header\n          accessor\n        }\n      }\n    }\n  }\n"])));
export var LEAVE_BALANCE_EXPORT_QUERY = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  query getLeaveSummaryBalanceExport(\n    $organisationId: String!\n    $teamId: String!\n    $startTime: Float!\n    $endTime: Float!\n    $userId: String\n    $timeZone: String!\n    $userRole: String!\n  ) {\n    getLeaveSummaryBalanceExport(\n      organisationId: $organisationId\n      teamId: $teamId\n      startTime: $startTime\n      endTime: $endTime\n      userId: $userId\n      timeZone: $timeZone\n      userRole: $userRole\n    ) {\n      employee\n      date {\n        dateValue\n        available\n        booked\n        leaveCycle\n        carryForwardCount\n        leaveCount\n      }\n      totalLeave\n    }\n  }\n"])));
export var LIST_LEAVE_TYPE_QUERY = gql(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  query getleaveHistroyTypeList($organisationId: String!, $userId: String!) {\n    getleaveHistroyTypeList(organisationId: $organisationId, userId: $userId) {\n      value\n      label\n      leaveTypeCount\n    }\n  }\n"])));
export var ADD_USER_LEAVE_TYPE_MUTATION = gql(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  mutation addUserLeaveTypes(\n    $organisationId: String!\n    $userId: String!\n    $leaveTypeId: String!\n    $leaveTypeDaysCount: Int\n  ) {\n    addUserLeaveTypes(\n      organisationId: $organisationId\n      userId: $userId\n      leaveTypeId: $leaveTypeId\n      leaveTypeDaysCount: $leaveTypeDaysCount\n    )\n  }\n"])));
export var GET_SHORT_LEAVE_HISTORY_QUERY = gql(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  query getShortleaveHistory(\n    $organisationId: String!\n    $userId: String\n    $timeZone: String!\n    $limit: Int!\n    $offset: Int\n    $startTime: Float\n    $endTime: Float!\n  ) {\n    getShortleaveHistory(\n      organisationId: $organisationId\n      userId: $userId\n      timeZone: $timeZone\n      limit: $limit\n      offset: $offset\n      startTime: $startTime\n      endTime: $endTime\n    ) {\n      userId\n      appliedShortLeave\n      approvedShortLeave\n      pendingShortLeave\n      rejectedShortLeave\n      user {\n        userId\n        displayName\n        uniqueUrl\n        profileUrl\n      }\n    }\n  }\n"])));
export var USER_SHORT_LEAVE_HISTORY_QUERY = gql(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  query userShortLeaveHistory(\n    $organisationId: String!\n    $userId: String!\n    $timeZone: String!\n    $limit: Int!\n    $offset: Int\n    $startTime: Float\n    $endTime: Float\n  ) {\n    userShortLeaveHistory(\n      organisationId: $organisationId\n      userId: $userId\n      timeZone: $timeZone\n      limit: $limit\n      offset: $offset\n      startTime: $startTime\n      endTime: $endTime\n    ) {\n      permissionId\n      leaveStartDateFormat\n      permissionStartTime\n      permissionEndTime\n      appliedAt\n      permissionRemark\n      appliedStatus\n      totalPermissionHour\n    }\n  }\n"])));
export var GET_EXPORT_SHORT_LEAVE_HISTORY_QUERY = gql(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  query getExportShortleaveHistory(\n    $organisationId: String!\n    $userId: String\n    $timeZone: String!\n    $startTime: Float\n    $endTime: Float!\n  ) {\n    getExportShortleaveHistory(\n      organisationId: $organisationId\n      userId: $userId\n      timeZone: $timeZone\n      startTime: $startTime\n      endTime: $endTime\n    ) {\n      userId\n      appliedShortLeave\n      approvedShortLeave\n      pendingShortLeave\n      rejectedShortLeave\n      user {\n        userId\n        displayName\n        uniqueUrl\n        profileUrl\n      }\n    }\n  }\n"])));