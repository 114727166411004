var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10;
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import { gql } from "@apollo/client";
export var USER_SIDE_MENU_QUERY = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  query UserSideMenuListing(\n    $organisationId: String!\n    $teamId: String!\n    $limit: Int!\n    $offset: Int!\n    $search: String\n    $userRole: String!\n    $pageName: String\n  ) {\n    userSideMenuListing(\n      organisationId: $organisationId\n      teamId: $teamId\n      limit: $limit\n      offset: $offset\n      search: $search\n      userRole: $userRole\n      pageName: $pageName\n    ) {\n      id\n      name\n      profile\n      color\n      profileUrl\n      uniqueUrl\n    }\n  }\n"])));
export var ORGANISATION_TEAMS_QUERY = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  query OrganisationTeams($organisationId: String!, $from: String) {\n    organisationTeams(organisationId: $organisationId, from: $from) {\n      teamName\n      teamId\n      roleId\n      roleName\n    }\n  }\n"])));
export var ORGANISATION_DETAILS_QUERY = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  query OrganisationDetails($userId: String!, $from: String) {\n    organisationDetails(userId: $userId, from: $from) {\n      organisationId\n      organisationName\n      userCount\n      menuDetails\n      billing {\n        planName\n        planId\n        trialStart\n        trialEnd\n        isTrial\n        planStatus\n        stoppedReason\n        teamCount\n        userCount\n        planEnd\n        planType\n        paymentType\n        failedPlanEnd\n        beforePlanEndAlert\n      }\n      organisationStatus\n    }\n  }\n"])));
export var ORGANISATION_TEAMS_AND_ROLE_QUERY = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  query OrganisationTeamAndRole($organisationId: String!) {\n    organisationTeamAndRole(organisationId: $organisationId) {\n      role {\n        roleId\n        roleName\n      }\n      team {\n        teamId\n        teamName\n      }\n    }\n  }\n"])));
export var WEB_ORGANISATION_SETTINGS_QUERY = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  query WebOrganisationSettings($organisationId: String!) {\n    webOrganisationSettings(organisationId: $organisationId) {\n      featureName\n      featureLabel\n      value\n      valueType\n      unit\n    }\n  }\n"])));
export var GET_ORGANISATION_SETTINGS_QUERY = gql(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  query GetOrganisationSettings($organisationId: String!) {\n    getOrganisationSettings(organisationId: $organisationId) {\n      featureName\n      featureLabel\n      value\n      valueType\n      unit\n    }\n  }\n"])));

// export const REVIEW_APPS_COUNT_QUERY = gql`
//   query ReviewAppsCount($organisationId: String!) {
//     reviewAppsCount(organisationId: $organisationId)
//   }
// `;

export var USER_TOUR_STATUS_QUERY = gql(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  query UserTourStatus($userId: String!) {\n    userTourStatus(userId: $userId)\n  }\n"])));
export var UPDATE_TOUR_MUTATION = gql(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  mutation UpdateUserTour($userId: String!) {\n    updateUserTour(userId: $userId)\n  }\n"])));
export var USER_ROLES_QUERY = gql(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  query UserRoles($organisationId: String!) {\n    userRoles(organisationId: $organisationId) {\n      roleId\n      roleName\n    }\n  }\n"])));
export var PROJECT_LISTING_QUERY = gql(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  query ProjectList($organisationId: String!, $url: String!, $role: String!) {\n    projectList(organisationId: $organisationId, url: $url, role: $role) {\n      projectId\n      projectName\n    }\n  }\n"])));