import "./wdyr";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { ApolloProvider } from "@workfolio/shared/node_modules/@apollo/client";
import client from "@workfolio/shared/build/apollo/apollo-config";
//import * as Sentry from "@sentry/react";
//import { Integrations } from "@sentry/tracing";

// Sentry.init({
//   dsn: "https://05c42f38381c4f40928d2898de644d0e@o463795.ingest.sentry.io/5775508",
//   integrations: [new Integrations.BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
