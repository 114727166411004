var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11;
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import { gql } from "@apollo/client";

// export const REVIEW_APP_QUERY = gql`
//   query App($organisationId: String!, $teamId: String) {
//     apps(organisationId: $organisationId, teamId: $teamId) {
//       reviewApp {
//         title
//         appType
//         productivityStatus
//         appCategory
//         icon
//       }
//       reviewedApp {
//         title
//         appType
//         productivityStatus
//         appCategory
//         icon
//       }
//     }
//   }
// `;
export var REVIEW_APP_QUERY = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  query ReviewApp(\n    $organisationId: String!\n    $teamId: String!\n    $limit: Int!\n    $offset: Int!\n    $searchText: String\n    $role: String\n  ) {\n    reviewApps(\n      organisationId: $organisationId\n      teamId: $teamId\n      limit: $limit\n      offset: $offset\n      searchText: $searchText\n      role: $role\n    ) {\n      reviewApp {\n        title\n        appType\n        productivityStatus\n        appCategory\n        icon\n      }\n    }\n  }\n"])));
export var REVIEWED_APP_QUERY = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  query ReviewedApp(\n    $organisationId: String!\n    $teamId: String\n    $limit: Int!\n    $offset: Int!\n    $searchText: String\n    $role: String\n  ) {\n    reviewedApps(\n      organisationId: $organisationId\n      teamId: $teamId\n      limit: $limit\n      offset: $offset\n      searchText: $searchText\n      role: $role\n    ) {\n      reviewedApp {\n        title\n        appType\n        productivityStatus\n        appCategory\n        icon\n      }\n    }\n  }\n"])));
export var REVIEW_APP_MUTATION = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  mutation addAppProductivityStatus(\n    $appName: String!\n    $appType: String!\n    $appCategory: String\n    $productivityStatus: String!\n    $organisationId: String!\n    $teamId: String\n    $canBeIdle: Boolean!\n    $role: String\n  ) {\n    addAppProductivityStatus(\n      appName: $appName\n      appType: $appType\n      appCategory: $appCategory\n      productivityStatus: $productivityStatus\n      organisationId: $organisationId\n      teamId: $teamId\n      canBeIdle: $canBeIdle\n      role: $role\n    ) {\n      appName\n      appType\n      productivityStatus\n    }\n  }\n"])));
export var REVIEW_CATEGORY_QUERY = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  query Category($organisationId: String!, $teamId: String) {\n    categories(organisationId: $organisationId, teamId: $teamId) {\n      name\n      productivityStatus\n    }\n  }\n"])));
export var REVIEW_CATEGORY_MUTATION = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  mutation addCategoryProductivityStatus(\n    $categoryName: String!\n    $productivityStatus: String!\n    $organisationId: String!\n    $teamId: String\n    $canBeIdle: Boolean!\n  ) {\n    addCategoryProductivityStatus(\n      categoryName: $categoryName\n      productivityStatus: $productivityStatus\n      organisationId: $organisationId\n      teamId: $teamId\n      canBeIdle: $canBeIdle\n    ) {\n      categoryName\n      productivityStatus\n    }\n  }\n"])));
export var REVIEW_APPS_COUNT_QUERY = gql(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  query ReviewAppsCount($organisationId: String!) {\n    reviewAppsCount(organisationId: $organisationId)\n  }\n"])));
export var APPS_COUNT_QUERY = gql(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  query AppsCount($organisationId: String!, $teamId: String!, $role: String) {\n    appsCount(organisationId: $organisationId, teamId: $teamId, role: $role) {\n      reviewCount\n      reviewedCount\n    }\n  }\n"])));
export var REVIEW_APP_EXPORT_QUERY = gql(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  query ReviewAppExport($organisationId: String!, $teamId: String!, $role: String) {\n    reviewAppsExport(organisationId: $organisationId, teamId: $teamId, role: $role) {\n      reviewApp {\n        title\n        appType\n        productivityStatus\n        appCategory\n        icon\n      }\n    }\n  }\n"])));
export var REVIEWED_APP_EXPORT_QUERY = gql(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  query ReviewedAppExport($organisationId: String!, $teamId: String, $role: String) {\n    reviewedAppsExport(organisationId: $organisationId, teamId: $teamId, role: $role) {\n      reviewedApp {\n        title\n        appType\n        productivityStatus\n        appCategory\n        icon\n      }\n    }\n  }\n"])));
export var USED_REVIEW_REVIEWED_APP_QUERY = gql(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  query TopUsedReviewReviewedApps(\n    $organisationId: String!\n    $teamId: String\n    $limit: Int!\n    $offset: Int!\n    $searchText: String\n    $timeZone: String!\n    $role: String\n  ) {\n    topUsedReviewReviewedApps(\n      organisationId: $organisationId\n      teamId: $teamId\n      limit: $limit\n      offset: $offset\n      searchText: $searchText\n      timeZone: $timeZone\n      role: $role\n    ) {\n      topUsedReviewReviewedApp {\n        title\n        appType\n        productivityStatus\n        appCategory\n        icon\n        totalSec\n        totalSecInHour\n      }\n    }\n  }\n"])));
export var USED_REVIEW_REVIEWED_APP_EXPORT_QUERY = gql(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  query TopUsedReviewReviewedAppsExport($organisationId: String!, $teamId: String, $timeZone: String!, $role: String) {\n    topUsedReviewReviewedAppsExport(\n      organisationId: $organisationId\n      teamId: $teamId\n      timeZone: $timeZone\n      role: $role\n    ) {\n      topUsedReviewReviewedApp {\n        title\n        appType\n        productivityStatus\n        appCategory\n        icon\n        totalSec\n        totalSecInHour\n      }\n    }\n  }\n"])));