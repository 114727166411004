var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13;
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import { gql } from "@apollo/client";
export var ADD_LEAVE_DETAILS_MUTATION = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  mutation addLeaveDetails(\n    $organisationId: String!\n    $userId: String!\n    $leaveType: String!\n    $startDate: String!\n    $endDate: String\n    $dateDetails: [DateDetails]!\n    $leaveReason: String!\n    $totalLeave: Float!\n    $timeZone: String!\n    $freePlanStatus: String!\n  ) {\n    addLeaveDetails(\n      organisationId: $organisationId\n      userId: $userId\n      leaveType: $leaveType\n      startDate: $startDate\n      endDate: $endDate\n      dateDetails: $dateDetails\n      leaveReason: $leaveReason\n      totalLeave: $totalLeave\n      timeZone: $timeZone\n      freePlanStatus: $freePlanStatus\n    )\n  }\n"])));
export var LEAVE_LIST_QUERY = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  query leaveList(\n    $organisationId: String!\n    $userId: String!\n    $filter: String!\n    $timeZone: String!\n    $limit: Int!\n    $offset: Int!\n    $freePlanStartDate: String\n    $freePlanEndDate: String\n    $leaveType: String\n    $startTime: String\n    $endTime: String\n  ) {\n    leaveList(\n      organisationId: $organisationId\n      userId: $userId\n      filter: $filter\n      timeZone: $timeZone\n      limit: $limit\n      offset: $offset\n      freePlanStartDate: $freePlanStartDate\n      freePlanEndDate: $freePlanEndDate\n      leaveType: $leaveType\n      startTime: $startTime\n      endTime: $endTime\n    ) {\n      leaveId\n      leaveType\n      leaveStartDate\n      leaveEndDate\n      leaveDays\n      appliedStatus\n      leaveReason\n      appliedAt\n      dateDetails {\n        date\n        dayType\n        dateFormat\n        dayFormat\n        nonWorkingDayLeaveSession\n        dateFormatEmail\n        dateFormatTooltip\n      }\n      statusModifyDisplayName\n      leaveRejectReason\n      leaveTypeArchive\n      leaveStartDateFormat\n      leaveEndDateFormat\n      attendanceType\n      permissionTypeId\n      permissionStartTime\n      permissionEndTime\n\n      editStartTime\n      editEndTime\n    }\n  }\n"])));
export var LEAVE_CHART_QUERY = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  query leaveSummary($organisationId: String!, $userId: String!, $year: String!, $timeZone: String!) {\n    leaveSummary(organisationId: $organisationId, userId: $userId, year: $year, timeZone: $timeZone) {\n      appliedLeave\n      approvedLeave\n      pendingLeave\n      rejectedLeave\n      date\n    }\n  }\n"])));
export var EDIT_LEAVE_DETAILS_MUTATION = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  mutation editLeaveDetails(\n    $organisationId: String!\n    $userId: String!\n    $leaveType: String!\n    $startDate: String!\n    $endDate: String\n    $dateDetails: [DateDetails]!\n    $leaveReason: String!\n    $totalLeave: Float!\n    $timeZone: String!\n    $leaveId: String!\n    $oldDateDetails: [DateDetails]!\n    $oldLeaveTypeId: String!\n    $leaveTypeId: String!\n    $oldUserId: String!\n  ) {\n    editLeaveDetails(\n      organisationId: $organisationId\n      userId: $userId\n      leaveType: $leaveType\n      startDate: $startDate\n      endDate: $endDate\n      dateDetails: $dateDetails\n      leaveReason: $leaveReason\n      totalLeave: $totalLeave\n      timeZone: $timeZone\n      leaveId: $leaveId\n      oldDateDetails: $oldDateDetails\n      oldLeaveTypeId: $oldLeaveTypeId\n      leaveTypeId: $leaveTypeId\n      oldUserId: $oldUserId\n    )\n  }\n"])));
export var DELETE_LEAVE_DETAILS_MUTATION = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  mutation deleteLeave(\n    $organisationId: String!\n    $userId: String!\n    $dateDetails: [DateDetails]!\n    $timeZone: String!\n    $leaveId: String!\n    $deleteLeaveType: String\n    $freePlanStatus: String!\n    $attendanceType: String\n  ) {\n    deleteLeave(\n      organisationId: $organisationId\n      userId: $userId\n      dateDetails: $dateDetails\n      timeZone: $timeZone\n      leaveId: $leaveId\n      deleteLeaveType: $deleteLeaveType\n      freePlanStatus: $freePlanStatus\n      attendanceType: $attendanceType\n    )\n  }\n"])));
export var LEAVE_LIST_COUNT_QUERY = gql(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  query leaveListCount(\n    $organisationId: String!\n    $userId: String!\n    $timeZone: String!\n    $freePlanStartDate: String\n    $freePlanEndDate: String\n    $leaveType: String\n    $startTime: String\n    $endTime: String\n  ) {\n    leaveListCount(\n      organisationId: $organisationId\n      userId: $userId\n      timeZone: $timeZone\n      freePlanStartDate: $freePlanStartDate\n      freePlanEndDate: $freePlanEndDate\n      leaveType: $leaveType\n      startTime: $startTime\n      endTime: $endTime\n    ) {\n      upcomingCount\n      pastCount\n    }\n  }\n"])));
export var LIST_LEAVE_TYPE_QUERY = gql(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  query leaveTypeList(\n    $organisationId: String!\n    $timeZone: String!\n    $userId: String!\n    $startDate: String\n    $endDate: String\n  ) {\n    leaveTypeList(\n      organisationId: $organisationId\n      timeZone: $timeZone\n      userId: $userId\n      startDate: $startDate\n      endDate: $endDate\n    ) {\n      value\n      label\n      leaveTypeCount\n      takenLeave\n      yearWiseLeave\n      leaveYearSettingsData\n      available\n    }\n  }\n"])));
export var LEAVE_TYPE_SETTINGS_LIST_QUERY = gql(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  query leaveTypeCardLists($organisationId: String!, $timeZone: String!, $startTime: Float!, $endTime: Float!) {\n    leaveTypeCardLists(organisationId: $organisationId, timeZone: $timeZone, startTime: $startTime, endTime: $endTime) {\n      leaveTypeName\n      leaveTypeId\n      leaveTypeCount\n      takenLeave\n      leaveValidity\n      available\n      resetType\n    }\n  }\n"])));
export var LEAVE_SETTINGS_APPROVED_LIST_QUERY = gql(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  query leaveSettingsPermission($organisationId: String!) {\n    leaveSettingsPermission(organisationId: $organisationId) {\n      leavePermissions\n    }\n  }\n"])));
export var ADD_PERMISSION_DETAILS_MUTATION = gql(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  mutation addLeavePermission(\n    $organisationId: String!\n    $userId: String!\n    $permissionTypeId: String!\n    $permissionDate: String!\n    $permissionRemark: String!\n    $timeZone: String!\n    $startTime: String!\n    $endTime: String!\n  ) {\n    addLeavePermission(\n      organisationId: $organisationId\n      userId: $userId\n      permissionTypeId: $permissionTypeId\n      permissionDate: $permissionDate\n      permissionRemark: $permissionRemark\n      timeZone: $timeZone\n      startTime: $startTime\n      endTime: $endTime\n    )\n  }\n"])));
export var LIST_PERMISSION_TYPE_QUERY = gql(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  query permissionTypeList($organisationId: String!, $timeZone: String!, $userId: String!) {\n    permissionTypeList(organisationId: $organisationId, timeZone: $timeZone, userId: $userId) {\n      value\n      label\n      applicableWithin\n      totalAllowedTimeInSec\n      totalAllowedTime\n      totalAllowedTimeInMin\n      minTimePerRequestInSec\n      minTimePerRequest\n      minTimePerRequestInMin\n      maxTimePerRequestInSec\n      maxTimePerRequest\n      maxTimePerRequestInMin\n      totalAllowedRequest\n    }\n  }\n"])));
export var DELETE_PERMISSION_DETAILS_MUTATION = gql(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n  mutation deletePermission($organisationId: String!, $userId: String!, $permissionId: String!, $timeZone: String!) {\n    deletePermission(organisationId: $organisationId, userId: $userId, permissionId: $permissionId, timeZone: $timeZone)\n  }\n"])));
export var EDIT_PERMISSION_DETAILS_MUTATION = gql(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n  mutation editLeavePermission(\n    $organisationId: String!\n    $userId: String!\n    $permissionTypeId: String!\n    $permissionDate: Float!\n    $permissionRemark: String!\n    $timeZone: String!\n    $startTime: String!\n    $endTime: String!\n    $permissionId: String!\n    $oldPermissionDate: Float!\n  ) {\n    editLeavePermission(\n      organisationId: $organisationId\n      userId: $userId\n      permissionTypeId: $permissionTypeId\n      permissionDate: $permissionDate\n      permissionRemark: $permissionRemark\n      timeZone: $timeZone\n      startTime: $startTime\n      endTime: $endTime\n      permissionId: $permissionId\n      oldPermissionDate: $oldPermissionDate\n    )\n  }\n"])));