export var personalDataTabs = [{
  path: "/account-settings",
  name: "Account Settings",
  id: "account-settings-link"
},
// {
//   path: "#",
//   name: "Refer & Earn",
//   type: "button",
//   id: "workfolio-affiliates",
// },
{
  path: "#",
  name: "Send bug report",
  type: "button",
  id: "send-log"
}, {
  path: "#",
  type: "button",
  name: "Sign Out",
  id: "sign-out-button"
}];